import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  root: {
    marginY: "4px",
  },
  container: {
    border: "none",
    background: "rgba(255, 255, 255, 0.4)",
    borderRadius: "24px",
    padding: "20px",
    marginBottom: 1,
    _last: {
      marginBottom: 0,
    },
  },
  button: {
    padding: "16px",
    _hover: {
      backgroundColor: "transparent",
    },
  },
  panel: {
    padding: 2,
    paddingTop: 0,
  },
  icon: {
    color: "black",
  },
});

export const accordionTheme = defineMultiStyleConfig({
  baseStyle,
});
