import { useCallback, useEffect, useState } from "react";
import { Survey } from "../types/surveys";
import { fetchMyEventSurveys } from "../services/api/surveys";
import { useCurrentUser } from "../providers/CurrentUserProvider";

interface UseMyEventSurveys {
  surveys: Survey[];
  loaded: boolean;
  refetch: () => Promise<void>;
}

export const useMyEventSurveys = (
  eventId: string | undefined
): UseMyEventSurveys => {
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [loaded, setLoaded] = useState(false);
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!eventId || !currentUser) return;

    const surveys = await fetchMyEventSurveys(eventId);
    setSurveys(surveys);
    setLoaded(true);
  }, [eventId, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { surveys, loaded, refetch: fetch };
};
