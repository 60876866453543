import { useCallback, useEffect, useState } from "react";
import { Survey } from "../types/surveys";
import { fetchEventSurveys } from "../services/api/surveys";
import { useCurrentUser } from "../providers/CurrentUserProvider";

interface UseEventSurveys {
  surveys: Survey[] | undefined;
}

export const useEventSurveys = (
  eventId: string | undefined
): UseEventSurveys => {
  const [surveys, setSurveys] = useState<Survey[]>();
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!eventId || !currentUser) return;

    const surveys = await fetchEventSurveys(eventId);
    setSurveys(surveys);
  }, [eventId, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { surveys };
};
