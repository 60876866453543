import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Link,
  Icon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import {
  BiCopy,
  BiDotsHorizontalRounded,
  BiPencil,
  // BiTrashAlt,
} from "react-icons/bi";
import { DeleteConfirmationModal } from "../../../components/DeleteConfirmationModal";
import { useState } from "react";
import { Survey } from "../../../types/surveys";
import { deleteSurvey } from "../../../services/api/surveys";
import {
  organizationSurveyShowUrl,
  surveyEditUrl,
} from "../../../services/routes/urlBuilder";
import { Organization } from "../../../types/organization";

interface ManageSurveyMenuProps {
  organization: Organization;
  survey: Survey;
  onDelete?: () => void;
}

export const ManageSurveyMenu: React.FC<ManageSurveyMenuProps> = ({
  organization,
  survey,
  onDelete,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toast = useToast();

  const handleGetFormLink = () => {
    const url = `${process.env.REACT_APP_BASE_URL}${organizationSurveyShowUrl(
      organization.slug,
      survey.id
    )}`;
    navigator.clipboard.writeText(url);
    toast({
      description: "Form link copied to clipboard",
      status: "success",
      duration: 2000,
    });
  };

  const handleDelete = async () => {
    await deleteSurvey(organization.id, survey.id);
    setIsOpen(false);
    onDelete?.();
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon as={BiDotsHorizontalRounded} />}
          variant="ghost"
        />
        <MenuList>
          <Link
            as={RouterLink}
            to={surveyEditUrl(organization.slug, survey.id)}
          >
            <MenuItem>
              <Icon as={BiPencil} marginRight={2} />
              <Text size="md">Edit</Text>
            </MenuItem>
          </Link>
          <MenuItem onClick={handleGetFormLink}>
            <Icon as={BiCopy} marginRight={2} />
            <Text size="md">Get form link</Text>
          </MenuItem>
          {/* <Link onClick={() => setIsOpen(true)}>
            <MenuItem>
              <Icon as={BiTrashAlt} marginRight={2} />
              <Text size="md">Disable</Text>
            </MenuItem>
          </Link> */}
        </MenuList>
      </Menu>
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
};
