import { ButtonLink } from "../../../components/ButtonLink";
import { surveyNewUrl } from "../../../services/routes/urlBuilder";
import { Panel } from "../../../components/Panel";
import { useCurrentOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import { SurveyItem } from "./SurveyItem";
import { Outlet } from "react-router-dom";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { Heading, Text, VStack } from "@chakra-ui/react";
import {
  hasPermission,
  PERMISSION_WAIVERS,
} from "../../../services/permissions";
import { useOrganizationSurveys } from "../../../hooks/useOrganizationSurveys";

export const SurveysList: React.FC = () => {
  const { organization } = useCurrentOrganization();
  const { currentOrganizationUser } = useCurrentOrganizationUser();

  const { surveys, refetch } = useOrganizationSurveys(organization?.id);

  const handleDelete = async () => {
    await refetch();
  };

  const canManageWaivers =
    currentOrganizationUser &&
    (currentOrganizationUser.is_owner ||
      hasPermission(currentOrganizationUser.permissions, PERMISSION_WAIVERS));

  if (!organization) return null;

  return (
    <>
      <Panel>
        <VStack align="stretch" gap={3}>
          <Heading as="h2" size="lg">
            Intake forms and waivers
          </Heading>

          <Text size={"md"}>
            Ask attendees to sign a waiver, or collect personal details like
            emergency contacts, injuries and personal goals
          </Text>

          {surveys?.map((survey) => (
            <SurveyItem
              key={survey.id}
              survey={survey}
              onDelete={handleDelete}
              canManage={!!canManageWaivers}
            />
          ))}
        </VStack>

        {canManageWaivers && (
          <ButtonLink
            to={surveyNewUrl(organization.slug)}
            buttonProps={{
              size: "lg",
              variant: "primary",
              width: "100%",
              marginTop: 4,
            }}
          >
            Create new intake form
          </ButtonLink>
        )}
      </Panel>

      <Outlet />
    </>
  );
};
