import { useForm } from "react-hook-form";
import { VianikoEvent } from "../../../../types/events";
import { StyledRegisteredInput } from "../../../../components/forms/StyledRegisteredInput";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Select,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { StyledDateTimeInput } from "../../../../components/forms/StyledDateTimeInput";
import { OrganizationUserSelectInput } from "../../../../components/OrganizationUserSelectInput";
import { PaymentConfigurationFormSection } from "./PaymentConfigurationFormSection";
import { Panel } from "../../../../components/Panel";
import { StyledTextarea } from "../../../../components/forms/StyledTextarea";
import { format } from "date-fns";
import { timezoneOptions } from "../../../../services/timezoneOptions";
import { useEffect, useMemo, useState } from "react";
import { useAllRelatedEvents } from "../../../../hooks/useAllRelatedEvents";
import { selectThemeProps } from "../../../../services/theme/overrides/select";
import { PhotoUploadInput } from "../../../../components/forms/PhotoUploadInput";
import { RecurringEvent } from "../../../../types/recurring_events";
import { FrequencyFormSection } from "../../../recurring_events/components/FrequencyFormSection";
import {
  getEventDuration,
  getEventCalendarDayCount,
  getDefaultEventStartDateTime,
  getDefaultEventEndDateTime,
  getUpdatedStartAtWhenEndAtChanged,
  getUpdatedEndAtWhenStartAtChanged,
  getUpdatedEventDurationWhenEndAtChanged,
  getUpdatedEventCalendarDays,
  getDefaultTimeZone,
} from "../../../../services/components/eventform.utils";
import { SubmitConfirmationModal } from "../../../../components/SubmitConfirmationModal";
import { RRule } from "rrule";
import { mapWeekdaysToString } from "../../../../services/formatter";
import ZonedDateTime from "../../../../services/ZonedDateTime";
import { useOrganization } from "../../../../providers/CurrentOrganizationProvider";

interface EventFormProps {
  onSubmit: (data: VianikoEvent & RecurringEvent, dirtyFields?: any) => void;
  submitText?: string;
  defaultValues?: Partial<VianikoEvent & RecurringEvent>;
  formAddon?: React.ReactNode;
  timeOnly?: boolean;
  hideRecurring?: boolean;
}

export const EventForm: React.FC<EventFormProps> = ({
  onSubmit: onSubmitProp,
  submitText,
  defaultValues,
  formAddon,
  timeOnly = false,
  hideRecurring = false,
}) => {
  const [confirmationTexts, setConfirmationTexts] = useState<string[]>([]);
  const [isRecurring, setIsRecurring] = useState(!!defaultValues?.rrule);
  // Event duration is set automatically from defaultValues and when end time is changed
  const [eventDurationInMinutes, setEventDurationInMinutes] = useState(
    getEventDuration(defaultValues)
  );
  const [eventDurationInCalendarDays, setEventDurationInCalendarDays] =
    useState(getEventCalendarDayCount(defaultValues));
  const { organizationId: organizationIdParam } = useParams();
  const { organization } = useOrganization(organizationIdParam);
  const [searchParams] = useSearchParams();
  const parentEventIdParam = searchParams.get("parentEventId");

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    watch,
    clearErrors,
    control,
  } = useForm<VianikoEvent & RecurringEvent>({
    defaultValues: {
      num_days_scheduled_ahead: 60,
      ...defaultValues,
      iana_timezone: getDefaultTimeZone(defaultValues),
      start_at: getDefaultEventStartDateTime(defaultValues),
      end_at: getDefaultEventEndDateTime(defaultValues),
    },
    mode: "onBlur",
  });
  const organizationId = organization?.id || defaultValues?.organization_id;

  useEffect(() => {
    if (!parentEventIdParam) return;
    setValue("parent_event_id", parentEventIdParam);
  }, [parentEventIdParam, setValue]);

  const timezone = watch("iana_timezone");
  const parentEventId = watch("parent_event_id");
  const start_at = watch(
    "start_at",
    getDefaultEventStartDateTime(defaultValues)
  );
  const end_at = watch("end_at", getDefaultEventEndDateTime(defaultValues));

  const { events: allRelatedEvents } = useAllRelatedEvents(parentEventId);

  const selectedTimezoneAbbreviation = useMemo(() => {
    const abbreviation = timezoneOptions.find(
      (option) => option.iana_timezone === timezone
    )?.abbreviation;
    const defaultAbbreviation = timezoneOptions.find(
      (option) =>
        option.iana_timezone ===
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )?.abbreviation;

    return abbreviation || defaultAbbreviation;
  }, [timezone]);

  const onTimezoneChange = (previousTimezone: string, newTimezone: string) => {
    const zdt_start_at = ZonedDateTime.fromUtc(start_at, previousTimezone);
    zdt_start_at.setTimezone(newTimezone);
    setValue("start_at", zdt_start_at.toISOUtc(), {
      shouldDirty: true,
    });

    const zdt_end_at = ZonedDateTime.fromUtc(end_at, previousTimezone);
    zdt_end_at.setTimezone(newTimezone);
    setValue("end_at", zdt_end_at.toISOUtc(), {
      shouldDirty: true,
    });
  };

  const submit = async (data: VianikoEvent & RecurringEvent) => {
    await onSubmitProp(data, dirtyFields);
  };

  const onSubmitButton = async (data: VianikoEvent & RecurringEvent) => {
    const defaultRRule = defaultValues?.rrule
      ? RRule.fromString(defaultValues.rrule)
      : null;
    const rrule = data.rrule ? RRule.fromString(data.rrule) : null;

    const daysRemoved = (defaultRRule?.options.byweekday || []).filter(
      (day) => !rrule?.options.byweekday?.includes(day)
    );

    const isSeriesEndAtSooner =
      rrule?.options.until &&
      defaultRRule?.options.until &&
      rrule?.options.until < defaultRRule?.options.until;

    const nextConfirmationTexts = [];
    if (daysRemoved.length > 0) {
      nextConfirmationTexts.push(`on ${mapWeekdaysToString(daysRemoved)}`);
    }

    if (isSeriesEndAtSooner && rrule?.options.until) {
      nextConfirmationTexts.push(
        `after ${format(rrule?.options.until?.toISOString(), "MMM d")}`
      );
    }

    setConfirmationTexts(nextConfirmationTexts);

    if (nextConfirmationTexts.length === 0) {
      await submit(data);
    }
  };

  const onConfirmSubmit = async () => {
    setConfirmationTexts([]);
    await handleSubmit(submit)();
  };

  const onStartDateTimeChanged = (newStartDateTime: string) => {
    const updatedEndAtDateTime = getUpdatedEndAtWhenStartAtChanged(
      newStartDateTime,
      end_at,
      timezone,
      eventDurationInMinutes,
      eventDurationInCalendarDays
    );

    if (updatedEndAtDateTime !== end_at)
      setValue("end_at", updatedEndAtDateTime, { shouldDirty: true });
    const newEventDayCount = getUpdatedEventCalendarDays(
      newStartDateTime,
      updatedEndAtDateTime,
      timezone
    );
    setEventDurationInCalendarDays(newEventDayCount);
  };

  const onEndDateTimeChanged = (newEndDateTime: string) => {
    const updatedStartAtDateTime = getUpdatedStartAtWhenEndAtChanged(
      newEndDateTime,
      start_at,
      timezone,
      eventDurationInMinutes
    );

    if (updatedStartAtDateTime !== start_at)
      setValue("start_at", updatedStartAtDateTime, { shouldDirty: true });

    const newEventDuration = getUpdatedEventDurationWhenEndAtChanged(
      updatedStartAtDateTime,
      newEndDateTime,
      timezone
    );
    setEventDurationInMinutes(newEventDuration);
    const newEventDayCount = getUpdatedEventCalendarDays(
      updatedStartAtDateTime,
      newEndDateTime,
      timezone
    );
    setEventDurationInCalendarDays(newEventDayCount);
  };

  if (!organizationId) return null;

  return (
    <>
      <SubmitConfirmationModal
        isOpen={confirmationTexts.length > 0}
        onClose={() => setConfirmationTexts([])}
        onSubmit={onConfirmSubmit}
        actionText="Submit anyway"
        bodyText={`This will delete all events in the series ${confirmationTexts.join(
          " and "
        )}`}
      />

      <form onSubmit={handleSubmit(onSubmitButton)}>
        {formAddon}

        <StyledRegisteredInput
          type="hidden"
          name="organization_id"
          register={register}
          inputProps={{ value: organizationId }}
        />

        <StyledRegisteredInput
          type="hidden"
          name="max_num_tickets"
          register={register}
          inputProps={{ value: 1 }}
        />

        <Panel>
          <VStack gap={4}>
            <StyledRegisteredInput
              name="name"
              label="Event name"
              type="text"
              register={register}
              options={{
                required: "This field is required",
                maxLength: {
                  message: "Must be less than 100 characters",
                  value: 100,
                },
              }}
              error={errors.name}
            />

            {parentEventId && (
              <Box width="100%">
                <HStack marginBottom={2}>
                  <Text as="label" htmlFor="parent_event_id" size="lg">
                    Session belongs to
                  </Text>
                  <Spacer />
                </HStack>

                <Select
                  variant="primary"
                  {...selectThemeProps}
                  {...register("parent_event_id")}
                >
                  {allRelatedEvents.map((event) => (
                    <option key={event.id} value={event.id}>
                      {event.name}
                    </option>
                  ))}
                </Select>
              </Box>
            )}

            <StyledTextarea
              name="description"
              label="Description"
              register={register}
              options={{
                maxLength: {
                  message: "Must be less than 20,000 characters",
                  value: 20000,
                },
              }}
              error={errors.description}
            />

            <StyledRegisteredInput
              name="location"
              label="Location"
              type="text"
              register={register}
              error={errors.location}
            />
          </VStack>
        </Panel>

        <Panel>
          <VStack gap={4} width="100%">
            <Flex align="start" wrap="wrap" flexDirection="row" width="100%">
              <StyledDateTimeInput
                timeOnly={timeOnly}
                name="start_at"
                label="Start time"
                timezone={watch("iana_timezone")}
                control={control}
                defaultValue={getDefaultEventStartDateTime(defaultValues)}
                error={errors.start_at}
                rules={{ required: "This field is required" }}
                styles={{ width: "fit-content", maxWidth: "100%" }}
                onChange={onStartDateTimeChanged}
              />

              <Spacer />
              <Select
                {...register("iana_timezone")}
                variant="subtle"
                defaultValue={
                  Intl.DateTimeFormat().resolvedOptions().timeZone ||
                  "America/New_York"
                }
                width="100px"
                style={{ width: "100px" }}
                onChange={(e) => {
                  onTimezoneChange(timezone, e.target.value);
                  setValue("iana_timezone", e.target.value, {
                    shouldDirty: true,
                  });
                }}
              >
                {timezoneOptions.map((option) => (
                  <option
                    key={option.iana_timezone}
                    value={option.iana_timezone}
                  >
                    {selectedTimezoneAbbreviation === option.abbreviation
                      ? option.abbreviation
                      : option.display_name}
                  </option>
                ))}
              </Select>
            </Flex>

            <StyledDateTimeInput
              timeOnly={timeOnly}
              name="end_at"
              label="End time"
              timezone={watch("iana_timezone")}
              control={control}
              defaultValue={getDefaultEventEndDateTime(defaultValues)}
              error={errors.end_at}
              rules={{ required: "This field is required" }}
              onChange={onEndDateTimeChanged}
            />

            {!hideRecurring &&
              (defaultValues?.recurring_event_id || !defaultValues?.id) && (
                <HStack width="100%">
                  <Checkbox
                    isChecked={isRecurring}
                    onChange={() => setIsRecurring(!isRecurring)}
                  />
                  <Text>Repeat</Text>
                </HStack>
              )}

            {isRecurring && (
              <FrequencyFormSection
                control={control}
                name="rrule"
                timezone={timezone}
              />
            )}
          </VStack>
        </Panel>

        <Panel>
          <VStack gap={4} width="100%">
            <OrganizationUserSelectInput
              name="instructor_id"
              label="Instructor"
              organizationId={organizationId}
              register={register}
              error={errors.instructor_id}
              clearErrors={clearErrors}
              setValue={setValue}
              defaultValue={defaultValues?.instructor_id}
            />
            <StyledRegisteredInput
              name="capacity"
              label="Capacity"
              type="number"
              register={register}
              options={{
                min: {
                  message: "Must be at least 1",
                  value: 1,
                },
              }}
              error={errors.capacity}
            />
          </VStack>
        </Panel>

        <Panel>
          <PaymentConfigurationFormSection control={control} errors={errors} />
        </Panel>

        <Panel>
          <PhotoUploadInput
            name="photo_ids"
            control={control}
            type="multiple"
            previewProps={{
              height: "124px",
              width: "100%",
            }}
          />
        </Panel>

        <Button
          type="submit"
          variant="primary"
          width="100%"
          marginTop={6}
          marginBottom={12}
        >
          {submitText || "Save"}
        </Button>
      </form>
    </>
  );
};
