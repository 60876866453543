import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { BiMapPin } from "react-icons/bi";
import { Link as RouterLink } from "react-router-dom";
import { UserAvatar } from "../../../components/UserAvatar";
import { VianikoEvent } from "../../../types/events";
import { Panel } from "../../../components/Panel";
import { useInstructor } from "../../../hooks/useInstructor";
import {
  adminManageOrganizationRefundsUrl,
  userShowUrl,
} from "../../../services/routes/urlBuilder";
import { getImageUrl } from "../../../services/images";
import { getHtmlFromString } from "../../../services/formatter";
import { Organization } from "../../../types/organization";
import { TEXT_SECONDARY_COLOR } from "../../../services/theme/colors";
import { getRefundDescription } from "../../../services/refunds";
import { ButtonLink } from "../../../components/ButtonLink";

interface EventOverviewProps {
  event: VianikoEvent;
  organization: Organization;
  canManageRefunds: boolean;
}

export const EventOverview: React.FC<EventOverviewProps> = ({
  event,
  organization,
  canManageRefunds,
}) => {
  const { instructor } = useInstructor(event.instructor_id);

  const html = getHtmlFromString(event.description);

  return (
    <>
      <Panel>
        <Text dangerouslySetInnerHTML={{ __html: html }} />
        {instructor && (
          <Box marginY={4}>
            <Text size="md" marginY={4}>
              Instructor:
            </Text>
            <LinkBox>
              <HStack marginBottom={2}>
                <UserAvatar size="xs" user={instructor} />
                <LinkOverlay as={RouterLink} to={userShowUrl(instructor.id)}>
                  <Text size="sm">{instructor.full_name}</Text>
                </LinkOverlay>
              </HStack>
            </LinkBox>
          </Box>
        )}
        {event.location && (
          <LinkBox>
            <Text size="md" marginY={4}>
              Location:
            </Text>
            <HStack>
              <Icon as={BiMapPin} />
              <LinkOverlay
                as={RouterLink}
                to={`https://maps.google.com/?q=${event.location}`}
                isExternal
              >
                <Text size="md">{event.location}</Text>
              </LinkOverlay>
            </HStack>
          </LinkBox>
        )}

        <Box marginTop={4}>
          <Text size="sm" color={TEXT_SECONDARY_COLOR}>
            {getRefundDescription(organization)}
          </Text>
          {canManageRefunds && (
            <HStack>
              <Spacer />
              <ButtonLink
                to={adminManageOrganizationRefundsUrl(organization.slug)}
                buttonProps={{
                  variant: "ghost",
                  fontSize: "sm",
                }}
              >
                Setup refund rules for this organization
              </ButtonLink>
            </HStack>
          )}
        </Box>
      </Panel>
      {event.photo_ids && (
        <Flex flexWrap="wrap" gap={1}>
          {event.photo_ids.slice(1).map((photoId) => (
            <Image
              key={photoId}
              src={getImageUrl(photoId)}
              width="calc(50% - 2px)"
              height="200px"
              fit="cover"
              borderRadius="24px"
            />
          ))}
        </Flex>
      )}
    </>
  );
};
