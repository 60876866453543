import React from "react";
import { Survey } from "../../types/surveys";
import {
  SurveyFormSubmission,
  SurveyResponse,
} from "../../types/survey_responses";
import { Button, Heading, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { SurveyResponseQuestion } from "./SurveyResponseQuestion";
import { createSurveyResponse } from "../../services/api/surveys";

interface SurveyResponseFormProps {
  survey: Survey;
  eventId?: string;
  organizationId?: string;
  afterSubmit: (data: SurveyFormSubmission) => void;
  surveyResponse: SurveyResponse | null;
}

export const SurveyResponseForm: React.FC<SurveyResponseFormProps> = ({
  survey,
  eventId,
  organizationId,
  afterSubmit: afterSubmitProp,
  surveyResponse,
}) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<SurveyFormSubmission>({
    defaultValues: {
      ...surveyResponse,
      survey_id: survey.id,
      event_id: eventId,
      organization_id: organizationId,
    },

    mode: "onBlur",
  });

  const onSubmit = async (data: SurveyFormSubmission) => {
    await createSurveyResponse(survey.id, data);
    afterSubmitProp(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack align="stretch" spacing={4}>
        <Heading size="lg" as="h1">
          {survey.title}
        </Heading>

        {survey.survey_questions?.map((question, index) => (
          <SurveyResponseQuestion
            key={question.id}
            question={question}
            register={register}
            control={control}
            name={`survey_question_answers.${index}`}
            error={errors["survey_question_answers"]?.[index]}
          />
        ))}

        <Button variant="primary" size="lg" width="100%" type="submit">
          Submit
        </Button>
      </VStack>
    </form>
  );
};
