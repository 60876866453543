import { Panel } from "../../../components/Panel";
import { SelectMenu } from "../../../components/forms/SelectMenuInput";
import { BiTrash } from "react-icons/bi";
import { HiOutlineBars2, HiOutlineBars3 } from "react-icons/hi2";
import { HStack, Icon, IconButton, VStack, Box } from "@chakra-ui/react";
import { useRef } from "react";
import { QuestionTypeFormSection } from "./QuestionTypeFormSection";
import {
  MdOutlineCheckBox,
  MdOutlineDragIndicator,
  MdOutlineRadioButtonChecked,
} from "react-icons/md";
import { useDrag, useDrop } from "react-dnd";
import { useFormContext } from "react-hook-form";
import { AiOutlineSignature } from "react-icons/ai";
import { QuestionsFormStateLocation } from "../../../types/surveys";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const QuestionPanel = ({
  field,
  index,
  remove,
  moveQuestion,
  name,
  canBeDeleted = true,
}: {
  field: any;
  index: number;
  remove: (index: number) => void;
  moveQuestion: (dragIndex: number, hoverIndex: number) => void;
  name: `${QuestionsFormStateLocation}.${number}`;
  canBeDeleted?: boolean;
}) => {
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const { control } = useFormContext();

  const [{ isDragging }, drag, preview] = useDrag({
    type: "QUESTION",
    item: { type: "QUESTION", index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "QUESTION",
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveQuestion(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(dragRef);
  preview(previewRef);
  drop(previewRef);

  return (
    <Panel
      key={field.id}
      width="100%"
      ref={previewRef}
      opacity={isDragging ? 0.5 : 1}
      position="relative"
    >
      <VStack align="stretch" spacing={4}>
        <HStack>
          <Box cursor="grab" _active={{ cursor: "grabbing" }} ref={dragRef}>
            <Icon as={MdOutlineDragIndicator} />
          </Box>
          <SelectMenu<any>
            options={[
              {
                value: "short_text",
                label: "Short answer",
                icon: <Icon as={HiOutlineBars2} />,
              },
              {
                value: "long_text",
                label: "Long answer",
                icon: <Icon as={HiOutlineBars3} />,
              },
              {
                value: "multiple_choice",
                label: "Multiple choice",
                icon: <Icon as={MdOutlineRadioButtonChecked} />,
              },
              {
                value: "multiple_choice_checkbox",
                label: "Checkboxes",
                icon: <Icon as={MdOutlineCheckBox} />,
              },
              {
                value: "waiver",
                label: "Waiver",
                icon: <Icon as={AiOutlineSignature} />,
              },
            ]}
            name={`${name}.question_type`}
            control={control}
          />

          {canBeDeleted && (
            <IconButton
              variant="ghost"
              size="lg"
              onClick={() => remove(index)}
              aria-label="Remove question"
              icon={<Icon as={BiTrash} />}
            />
          )}
        </HStack>

        <QuestionTypeFormSection name={name} />
      </VStack>
    </Panel>
  );
};
