import { Heading, VStack } from "@chakra-ui/react";
import { TicketTypeForm } from "./TicketTypeForm";
import { useNavigate, useParams } from "react-router-dom";
import { eventTicketsUrl } from "../../services/routes/urlBuilder";
import { useEvent } from "../../hooks/useEvent";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { PageBreadcrumb } from "../../components/PageBreadcrumb";

export const NewTicketTypePage: React.FC = () => {
  const { eventId } = useParams();
  const { event } = useEvent(eventId);
  const { organization } = useOrganization(event?.organization_id);

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!eventId) return;

    navigate(eventTicketsUrl(eventId));
  };

  if (!event || !organization) return null;

  return (
    <VStack align="stretch">
      {eventId && (
        <PageBreadcrumb to={eventTicketsUrl(eventId)} label={event.name} />
      )}
      <Heading as="h1" size="lg" marginLeft={5}>
        Create ticket
      </Heading>
      <TicketTypeForm
        event={event}
        organization={organization}
        onSubmit={handleSubmit}
      />
    </VStack>
  );
};
