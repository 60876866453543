import { Survey } from "../../types/surveys";
import {
  SurveyFormSubmission,
  SurveyResponse,
} from "../../types/survey_responses";
import axiosInstance from "./axiosInstance";

export const createSurvey = async (data: Survey) => {
  const result = await axiosInstance.post(`/surveys`, data);
  return result.data;
};

export const updateSurvey = async (id: string, data: Survey) => {
  const result = await axiosInstance.put(`/surveys/${id}`, data);
  return result.data;
};

export const deleteSurvey = async (
  organizationId: string,
  surveyId: string
) => {
  const result = await axiosInstance.delete(
    `/organizations/${organizationId}/surveys/${surveyId}`
  );
  return result.data;
};

export const fetchSurvey = async (id: string) => {
  const result = await axiosInstance.get(`/surveys/${id}`);
  return result.data;
};

export const createSurveyResponse = async (
  surveyId: string,
  data: SurveyFormSubmission
): Promise<SurveyResponse> => {
  const result = await axiosInstance.post(
    `/surveys/${surveyId}/responses`,
    data
  );
  return result.data;
};

export const fetchSurveyResponse = async (surveyId: string) => {
  const result = await axiosInstance.get(`/surveys/${surveyId}/responses/my`);
  return result.data;
};

export const fetchOrganizationSurveys = async (organizationId: string) => {
  const { data } = await axiosInstance.get(
    `/surveys/organizations/${organizationId}`
  );
  return data;
};

export const fetchEventSurveys = async (eventId: string) => {
  const { data } = await axiosInstance.get(`/surveys/events/${eventId}`);
  return data;
};

export const fetchMyEventSurveys = async (eventId: string) => {
  const { data } = await axiosInstance.get(`/surveys/events/${eventId}/my`);
  return data;
};

export const enableSurveyForEvent = async (
  surveyId: string,
  eventId: string
): Promise<void> => {
  await axiosInstance.post(`/surveys/${surveyId}/events/${eventId}`);
};

export const disableSurveyForEvent = async (
  surveyId: string,
  eventId: string
): Promise<void> => {
  await axiosInstance.delete(`/surveys/${surveyId}/events/${eventId}`);
};
