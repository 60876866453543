import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
  Link as RouterLink,
  useParams,
} from "react-router-dom";
import { Box, Link, Tab, TabList, Tabs } from "@chakra-ui/react";
import { VianikoEvent } from "../../../types/events";
import { useOrganizationUser } from "../../../providers/CurrentOrganizationUserProvider";
import {
  eventAttendeesUrl,
  eventMessagesUrl,
  eventProgramUrl,
  eventShowUrl,
  eventSurveysUrl,
  eventTicketsUrl,
  organizationShowUrl,
} from "../../../services/routes/urlBuilder";
import { useCurrentEventUser } from "../../../providers/CurrentEventUserProvider";
import { EventThemeCard } from "../components/EventThemeCard";
import { Organization } from "../../../types/organization";
import { ManageEventMenuListItems } from "../ManageEventMenuListItems";
import { ExtendedUser, useAttendees } from "../hooks/useAttendees";
import { useEffect, useMemo, useState } from "react";
import { PageHeader } from "../../../components/PageHeader";
import { EventInviteFriendsModal } from "../components/EventInviteFriendsModal";
import { TicketTypeSelectionModal } from "../components/forms/TicketTypeSelectionModal";
import { EventActionButton } from "../components/EventActionButton";
import { useSubEvents } from "../hooks/useSubEvents";
import { useCurrentUser } from "../../../providers/CurrentUserProvider";
import { useEventTickets } from "../../../hooks/useEventTickets";
import { useTicketTypesAdmin } from "../../../hooks/useTicketTypesAdmin";
import {
  hasPermission,
  PERMISSION_CHECKIN,
  PERMISSION_MANAGE_EVENTS,
  PERMISSION_MANAGE_EVENT_TICKETS,
  PERMISSION_MANAGE_ORGANIZATION,
  PERMISSION_MANAGE_PASSES,
  PERMISSION_MESSAGE_ATTENDEES,
  PERMISSION_WAIVERS,
} from "../../../services/permissions";
import { Ticket } from "../../../types/ticket";
import { PageBreadcrumb } from "../../../components/PageBreadcrumb";
import { useEvent } from "../../../hooks/useEvent";
import { TicketType } from "../../../types/ticket_types";
import { useEventWithOrganization } from "../../../hooks/useEventWithOrganization";
import { useEventSurveys } from "../../../hooks/useEventSurveys";
import { useOrganizationSurveys } from "../../../hooks/useOrganizationSurveys";
import { Survey } from "../../../types/surveys";

export interface EventShowOutletContext {
  showAttendees: boolean;
  showProgram: boolean;
  showTickets: boolean;
  showMessages: boolean;
  showSurveys: boolean;
  canManageTickets: boolean;
  canManagePasses: boolean;
  canManagePayments: boolean;
  canManageOrganization: boolean;
  canManageSurveys: boolean;
  event: VianikoEvent;
  subEvents: VianikoEvent[];
  organization: Organization;
  tickets: Ticket[];
  ticketTypes: TicketType[];
  attendees: ExtendedUser[];
  eventSurveys: Survey[];
  organizationSurveys: Survey[];
  numAttendees: number;
  onBuyTickets: () => void;
  handleRefundTickets: () => void;
}

export const EventShowPage: React.FC = () => {
  const { eventId } = useParams();
  const { event, organization } = useEventWithOrganization(eventId);
  const { subEvents } = useSubEvents(event?.id);
  const { event: parentEvent } = useEvent(event?.parent_event_id);

  const { currentUser } = useCurrentUser();
  const { organizationUser } = useOrganizationUser(event?.organization_id);
  const { currentEventUser } = useCurrentEventUser();
  const { surveys: eventSurveys } = useEventSurveys(eventId);
  const { surveys: organizationSurveys } = useOrganizationSurveys(
    organization?.id
  );
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    attendees,
    refetch: refetchAttendees,
    numAttendees,
  } = useAttendees(event?.id);
  const { tickets } = useEventTickets(eventId);
  const { ticketTypes } = useTicketTypesAdmin(eventId);
  const [isInviteFriendsModalOpen, setIsInviteFriendsModalOpen] =
    useState(false);
  const [isTicketTypeSelectionModalOpen, setIsTicketTypeSelectionModalOpen] =
    useState(false);
  const isInvite = !!searchParams.get("invite");
  const isTicketSelection = !!searchParams.get("ticket_selection");

  useEffect(() => {
    if (isInvite) {
      setIsInviteFriendsModalOpen(true);
    }
  }, [isInvite]);

  useEffect(() => {
    if (isTicketSelection && !!currentUser) {
      setIsTicketTypeSelectionModalOpen(true);
    }
  }, [isTicketSelection, currentUser]);

  const handleDelete = async () => {
    if (organization) {
      navigate(organizationShowUrl(organization.slug));
    }
  };

  const handleRefundTickets = async () => {
    await refetchAttendees();
  };

  const handleCloseInviteModal = () => {
    setIsInviteFriendsModalOpen(false);
    if (event) {
      window.location.href = eventTicketsUrl(event.id);
    }
  };

  const onBuyTickets = () => {
    setIsTicketTypeSelectionModalOpen(true);
  };

  const isOrganizationOwner = !!organizationUser?.is_owner;

  const canManageTickets =
    isOrganizationOwner ||
    hasPermission(
      organizationUser?.permissions,
      PERMISSION_MANAGE_EVENT_TICKETS
    );
  const canManagePasses =
    isOrganizationOwner ||
    hasPermission(organizationUser?.permissions, PERMISSION_MANAGE_PASSES);
  const canManagePayments = isOrganizationOwner;
  const canManageEvents =
    isOrganizationOwner ||
    hasPermission(organizationUser?.permissions, PERMISSION_MANAGE_EVENTS);
  const canCheckIn =
    isOrganizationOwner ||
    hasPermission(organizationUser?.permissions, PERMISSION_CHECKIN);
  const canManageOrganization =
    isOrganizationOwner ||
    hasPermission(
      organizationUser?.permissions,
      PERMISSION_MANAGE_ORGANIZATION
    );
  const canManageSurveys =
    isOrganizationOwner ||
    hasPermission(organizationUser?.permissions, PERMISSION_WAIVERS);

  // TODO: review this logic: should people who can check in be able to see attendees?
  const showAttendees =
    isOrganizationOwner ||
    !!currentEventUser ||
    (!!organizationUser && tickets.length > 2);
  // TODO: who else should be able to see the program?
  const showProgram = isOrganizationOwner || subEvents.length > 0;
  const showTickets =
    (tickets.length > 0 || canManageTickets || canCheckIn || canManagePasses) &&
    !event?.parent_event_id;
  const showMessages =
    isOrganizationOwner ||
    hasPermission(organizationUser?.permissions, PERMISSION_MESSAGE_ATTENDEES);
  const showSurveys = canManageSurveys || !!currentEventUser;

  const tabIndex = useMemo(() => {
    if (pathname.includes("attendees")) {
      return 1;
    } else if (pathname.includes("program")) {
      const numPreviousTabs = showAttendees ? 2 : 1;
      return numPreviousTabs;
    } else if (pathname.includes("tickets")) {
      let numPreviousTabs = 1;
      if (showAttendees) numPreviousTabs++;
      if (showProgram) numPreviousTabs++;
      return numPreviousTabs;
    } else if (pathname.includes("messages")) {
      let numPreviousTabs = 1;
      if (showAttendees) numPreviousTabs++;
      if (showProgram) numPreviousTabs++;
      if (showTickets) numPreviousTabs++;
      return numPreviousTabs;
    } else if (pathname.includes("surveys")) {
      let numPreviousTabs = 1;
      if (showAttendees) numPreviousTabs++;
      if (showProgram) numPreviousTabs++;
      if (showTickets) numPreviousTabs++;
      if (showMessages) numPreviousTabs++;
      return numPreviousTabs;
    } else {
      return 0;
    }
  }, [pathname, showAttendees, showProgram, showTickets, showMessages]);

  if (!event || !organization) return null;

  return (
    <>
      <EventInviteFriendsModal
        event={event}
        isOpen={isInviteFriendsModalOpen}
        onClose={handleCloseInviteModal}
      />
      <TicketTypeSelectionModal
        event={event}
        organization={organization}
        isOpen={isTicketTypeSelectionModalOpen}
        onClose={() => setIsTicketTypeSelectionModalOpen(false)}
      />

      <PageHeader
        breadcrumbs={
          parentEvent ? (
            <PageBreadcrumb
              to={eventShowUrl(parentEvent.id)}
              label={parentEvent.name}
            />
          ) : organization ? (
            <PageBreadcrumb
              to={organizationShowUrl(organization.slug)}
              label={organization.name}
            />
          ) : null
        }
        menuListContent={
          canManageEvents ? (
            <ManageEventMenuListItems
              event={event}
              recurringEventId={event.recurring_event_id}
              onDelete={handleDelete}
            />
          ) : null
        }
      />

      <Box marginBottom={1}>
        <EventThemeCard event={event} organization={organization} />
      </Box>

      <EventActionButton
        event={event}
        onGetTicketsClick={() => setIsTicketTypeSelectionModalOpen(true)}
        onInviteFriendsClick={() => setIsInviteFriendsModalOpen(true)}
        canCheckIn={canCheckIn}
      />

      <Tabs marginY={2} index={tabIndex}>
        <TabList>
          <Link as={RouterLink} variant="unstyled" to={eventShowUrl(event.id)}>
            <Tab>Info</Tab>
          </Link>

          {showAttendees && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventAttendeesUrl(event.id)}
            >
              <Tab>{`${numAttendees} ${
                numAttendees === 1 ? "attendee" : "attendees"
              }`}</Tab>
            </Link>
          )}
          {showProgram && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventProgramUrl(event.id)}
            >
              <Tab>Program</Tab>
            </Link>
          )}

          {showTickets && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventTicketsUrl(event.id)}
            >
              <Tab>Tickets</Tab>
            </Link>
          )}

          {showMessages && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventMessagesUrl(event.id)}
            >
              <Tab>Messages</Tab>
            </Link>
          )}

          {showSurveys && (
            <Link
              as={RouterLink}
              variant="unstyled"
              to={eventSurveysUrl(event.id)}
            >
              <Tab>Forms</Tab>
            </Link>
          )}
        </TabList>
      </Tabs>

      <Outlet
        context={{
          showAttendees,
          showProgram,
          showTickets,
          showMessages,
          showSurveys,
          canManageTickets,
          canManagePasses,
          canManagePayments,
          canManageOrganization,
          canManageSurveys,
          event,
          subEvents,
          organization,
          tickets,
          ticketTypes,
          attendees,
          eventSurveys,
          organizationSurveys,
          numAttendees,
          onBuyTickets,
          handleRefundTickets,
        }}
      />
    </>
  );
};
