import { useEffect } from "react";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useCurrentUser } from "../../providers/CurrentUserProvider";
import { useEvent } from "../../hooks/useEvent";
import {
  eventPaymentUrl,
  eventShowUrl,
  eventSignAllSurveysUrl,
  paymentsRedirectorUrl,
  userEditUrl,
} from "../../services/routes/urlBuilder";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { useMyEventSurveys } from "../../hooks/useMyEventSurveys";

export const EventPaymentRedirector: React.FC = () => {
  const { currentUser } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const { eventId } = useParams();
  const { event } = useEvent(eventId);
  const { organization } = useOrganization(event?.organization_id);
  const navigate = useNavigate();

  const { surveys, loaded: surveysLoaded } = useMyEventSurveys(event?.id);

  const amountInCents = searchParams.get("amountInCents") || "0";
  const paymentToken =
    searchParams.get("paymentToken") === "undefined"
      ? undefined
      : searchParams.get("paymentToken") ?? undefined;

  useEffect(() => {
    const init = async () => {
      if (!event || !currentUser || !surveysLoaded) return;

      if (!currentUser.full_name) {
        navigate(
          userEditUrl(
            currentUser.id,
            paymentsRedirectorUrl(event.id, { amountInCents, paymentToken })
          )
        );
        return;
      }

      if (surveys.length > 0) {
        navigate(
          eventSignAllSurveysUrl(event.id, {
            redirectTo: eventPaymentUrl(event.id, {
              amountInCents,
              paymentToken,
            }),
          })
        );
        return;
      }

      if (paymentToken) {
        navigate(eventPaymentUrl(event.id, { amountInCents, paymentToken }));
      } else {
        navigate(eventShowUrl(event.id, { invite: true, welcome: true }));
      }
    };

    init();
  }, [
    event,
    currentUser,
    navigate,
    amountInCents,
    paymentToken,
    surveys,
    surveysLoaded,
    organization,
  ]);

  return <Outlet />;
};
