import {
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { VianikoEvent } from "../../../../types/events";
import { useForm } from "react-hook-form";
import { mobileModalThemeProps } from "../../../../services/theme/overrides/modal";
import { createPaymentIntent } from "../../../../services/api/payments";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  authUrl,
  eventPaymentUrl,
  eventShowUrl,
} from "../../../../services/routes/urlBuilder";
import { useEffect, useMemo, useState } from "react";
import { TicketTypeSelectionPanel } from "./TicketTypeSelectionPanel";
import { TEXT_SECONDARY_COLOR } from "../../../../services/theme/colors";
import { TicketTypeCodeForm } from "./TicketTypeCodeForm";
import { TicketType } from "../../../../types/ticket_types";
import { useCurrentUser } from "../../../../providers/CurrentUserProvider";
import { EventPassItem } from "./EventPassItem";
import { Organization } from "../../../../types/organization";

interface TicketTypeSelectionModalProps {
  event: VianikoEvent;
  organization: Organization;
  isOpen: boolean;
  onClose: () => void;
}

interface TicketTypeSelection {
  [key: string]: {
    ticketTypeId: string;
    quantity: number;
    amount_in_cents?: number;
  };
}

export const TicketTypeSelectionModal: React.FC<
  TicketTypeSelectionModalProps
> = ({ event, organization, isOpen, onClose }) => {
  const [codeShown, setCodeShown] = useState(false);
  const [unlockedTicketTypes, setUnlockedTicketTypes] = useState<TicketType[]>(
    []
  );
  const [zeroTicketsError, setZeroTicketsError] = useState(false);
  const { currentUser } = useCurrentUser();

  const [searchParams] = useSearchParams();
  const ticketTypeParam = searchParams.get("ticket_types") || "{}";

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
  } = useForm<TicketTypeSelection>({
    mode: "onBlur",
  });

  const ticketTypeIds = useMemo(() => {
    return event.ticket_types?.map((t) => t.id) ?? [];
  }, [event.ticket_types]);

  useEffect(() => {
    if (ticketTypeIds.length === 1) {
      const [firstTicketTypeId] = ticketTypeIds;
      setValue(ticketTypeIds[0], {
        ticketTypeId: firstTicketTypeId,
        quantity: 1,
      });
    }
  }, [ticketTypeIds, setValue]);

  useEffect(() => {
    const ticketTypeIdToQuanity = JSON.parse(
      decodeURIComponent(ticketTypeParam)
    );

    Object.entries(ticketTypeIdToQuanity).forEach(
      ([ticketTypeId, quantity]) => {
        setValue(ticketTypeId, {
          ticketTypeId,
          quantity: quantity as number,
        });
      }
    );
  }, [setValue, ticketTypeParam]);

  const modalContentProps = useBreakpointValue({
    base: mobileModalThemeProps,
    lg: {},
  });

  const onSubmit = async (data: TicketTypeSelection) => {
    if (!currentUser) {
      navigate(
        authUrl(
          eventShowUrl(event.id, {
            ticket_selection: true,
            ticket_types: Object.entries(data).reduce(
              (acc: any, [ticketTypeId, ticketType]) => {
                acc[ticketTypeId] = ticketType.quantity;
                return acc;
              },
              {}
            ),
          }),
          {
            authReason: "ticket_purchase",
          }
        )
      );
      return;
    }

    const numTickets = Object.values(data).reduce(
      (acc, datum) => acc + Number(datum.quantity),
      0
    );

    if (numTickets === 0) {
      setZeroTicketsError(true);
      return;
    } else {
      setZeroTicketsError(false);
    }

    const { paymentToken, amountInCents } = await createPaymentIntent(
      event.id,
      Object.entries(data).map(([ticketTypeId, ticketType]) => ({
        ticket_type_id: ticketTypeId,
        ...ticketType,
      }))
    );

    navigate(
      eventPaymentUrl(event.id, {
        paymentToken: paymentToken ?? "",
        amountInCents,
      })
    );
  };

  const handleValidCode = (ticketType: TicketType) => {
    if (!unlockedTicketTypes.find((t) => t.id === ticketType.id)) {
      setUnlockedTicketTypes([...unlockedTicketTypes, ticketType]);
    }
    setCodeShown(false);
  };

  return (
    <Portal>
      <Modal
        scrollBehavior="inside"
        size="md"
        isOpen={isOpen}
        onClose={onClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalOverlay />
          <ModalContent {...modalContentProps}>
            <ModalHeader>
              <HStack>
                <Heading size="lg" as="h2" margin={2}>
                  Select tickets
                </Heading>
              </HStack>
            </ModalHeader>
            <ModalCloseButton margin={4} />
            <ModalBody padding={1}>
              {event.passes && event.passes.length > 0 && (
                <>
                  {event.passes?.map((pass) => (
                    <EventPassItem
                      key={pass.id}
                      pass={pass}
                      event={event}
                      organization={organization}
                      onComplete={onClose}
                    />
                  ))}
                </>
              )}

              {[...(event.ticket_types || []), ...unlockedTicketTypes].map(
                (ticketType) => (
                  <TicketTypeSelectionPanel
                    key={ticketType.id}
                    ticketType={ticketType}
                    event={event}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    watch={watch}
                    control={control}
                  />
                )
              )}

              {codeShown && (
                <TicketTypeCodeForm
                  event={event}
                  onValidCode={handleValidCode}
                />
              )}

              <Button
                onClick={() => setCodeShown(!codeShown)}
                variant="link"
                color={TEXT_SECONDARY_COLOR}
              >
                Got a code?
              </Button>

              {zeroTicketsError && (
                <Text color="red.500" fontSize="sm" marginX={4}>
                  Please select at least one ticket
                </Text>
              )}
            </ModalBody>
            <ModalFooter>
              <Button type="submit" variant="primary" size="lg" width="100%">
                Check out
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </Portal>
  );
};
