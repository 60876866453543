import { useOutletContext } from "react-router-dom";
import { VStack } from "@chakra-ui/react";
import { EventShowOutletContext } from "..";
import { Panel } from "../../../../components/Panel";
import { ButtonLink } from "../../../../components/ButtonLink";
import { surveyNewUrl } from "../../../../services/routes/urlBuilder";
import { SurveysListItem } from "./SurveysListItem";

export const SurveysTab = () => {
  const {
    eventSurveys,
    organizationSurveys,
    canManageSurveys,
    organization,
    event,
  } = useOutletContext<EventShowOutletContext>();

  const nonEventSurveys = organizationSurveys.filter(
    (survey) =>
      !eventSurveys?.some((eventSurvey) => eventSurvey.id === survey.id)
  );

  return (
    <>
      <Panel>
        <VStack align="stretch" gap={3}>
          {eventSurveys?.map((survey) => (
            <SurveysListItem
              key={survey.id}
              event={event}
              organization={organization}
              survey={survey}
              canManage={!!canManageSurveys}
              isEnabled
            />
          ))}
        </VStack>

        {canManageSurveys && (
          <VStack align="stretch" gap={3}>
            <ButtonLink
              to={surveyNewUrl(organization.slug)}
              buttonProps={{
                size: "lg",
                variant: "primary",
                width: "100%",
                marginTop: 4,
              }}
            >
              Create new intake form
            </ButtonLink>

            {nonEventSurveys?.map((survey) => (
              <SurveysListItem
                key={survey.id}
                event={event}
                organization={organization}
                survey={survey}
                canManage={!!canManageSurveys}
                isEnabled={false}
              />
            ))}
          </VStack>
        )}
      </Panel>
    </>
  );
};
