import { useEffect, useState } from "react";
import { VianikoUser } from "../types/users";
import { getPublicUser } from "../services/api/users";

export const useInstructor = (
  instructorId: string
): { instructor: VianikoUser | undefined; instructorFetched: boolean } => {
  const [instructor, setInstructor] = useState<VianikoUser>();
  const [instructorFetched, setInstructorFetched] = useState<boolean>(false);

  useEffect(() => {
    setInstructorFetched(false);
    const fetchInstructor = async () => {
      if (instructorId) {
        const result = await getPublicUser(instructorId);
        setInstructor(result);
        setInstructorFetched(true);
      } else {
        setInstructorFetched(true);
      }
    };

    fetchInstructor();
  }, [instructorId]);

  return { instructor, instructorFetched };
};
