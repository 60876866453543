import { useState, useEffect } from "react";
import { VianikoEvent } from "../types/events";
import { fetchNonRecurringEvents } from "../services/api/organizations";
import { useCurrentUser } from "../providers/CurrentUserProvider";

interface UseNonRecurringFutureEventsResult {
  nonRecurringEvents: VianikoEvent[] | undefined;
}

export const useNonRecurringFutureEvents = (
  organizationId: string | undefined
): UseNonRecurringFutureEventsResult => {
  const [nonRecurringEvents, setNonRecurringEvents] = useState<
    VianikoEvent[] | undefined
  >(undefined);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const init = async () => {
      if (!currentUser || !organizationId) return;

      const events = await fetchNonRecurringEvents(organizationId);

      setNonRecurringEvents(events);
    };

    init();
  }, [organizationId, currentUser]);

  return { nonRecurringEvents };
};
