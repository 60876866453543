import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Link,
  Icon,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { BiDotsHorizontalRounded, BiPencil } from "react-icons/bi";
import { DeleteConfirmationModal } from "../../../../components/DeleteConfirmationModal";
import { useState } from "react";
import { Survey } from "../../../../types/surveys";
import { deleteSurvey } from "../../../../services/api/surveys";
import { surveyEditUrl } from "../../../../services/routes/urlBuilder";
import { Organization } from "../../../../types/organization";
import { VianikoEvent } from "../../../../types/events";

interface ManageSurveyMenuProps {
  organization: Organization;
  event: VianikoEvent;
  survey: Survey;
  isEnabled: boolean;
}

export const ManageSurveyMenu: React.FC<ManageSurveyMenuProps> = ({
  organization,
  event,
  survey,
  isEnabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = async () => {
    await deleteSurvey(organization.id, survey.id);
    setIsOpen(false);
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon as={BiDotsHorizontalRounded} />}
          variant="ghost"
        />
        <MenuList>
          <Link
            as={RouterLink}
            to={surveyEditUrl(organization.slug, survey.id)}
          >
            <MenuItem>
              <Icon as={BiPencil} marginRight={2} />
              <Text size="md">Edit</Text>
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      <DeleteConfirmationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onDelete={handleDelete}
      />
    </>
  );
};
