import { Survey } from "../../types/surveys";
import { SurveyForm } from "./forms/Form";
import { useSurvey } from "../../hooks/useSurvey";
import { useNavigate, useParams } from "react-router-dom";
import { updateSurvey } from "../../services/api/surveys";
import { organizationSurveysUrl } from "../../services/routes/urlBuilder";

export const EditSurveyPage: React.FC = () => {
  const { surveyId, organizationId } = useParams<{
    surveyId: string;
    organizationId: string;
  }>();
  const { survey } = useSurvey(surveyId);
  const navigate = useNavigate();

  const handleSubmit = async (data: Survey) => {
    if (!survey) {
      return;
    }

    await updateSurvey(survey.id, data);

    if (organizationId) {
      navigate(organizationSurveysUrl(organizationId));
    }
  };

  if (!survey) {
    return null;
  }

  return <SurveyForm defaultValues={survey} onSubmit={handleSubmit} />;
};
