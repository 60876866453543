import {
  Box,
  HStack,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

interface RadioTabOption {
  value: string;
  label: string | React.ReactNode;
}

interface RadioTabProps<T extends FieldValues> {
  options: RadioTabOption[];
  name: Path<T>;
  control: Control<T>;
  transform?: {
    input?: (value: any) => string;
    output?: (value: string) => any;
  };
}

interface RadioTabCardProps extends UseRadioProps {
  children: React.ReactNode;
  width: string;
}

const RadioTabCard: React.FC<RadioTabCardProps> = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" width={props.width} p={1}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderRadius="full"
        transition="all 0.2s"
        _checked={{
          bg: "white",
          color: "gray.800",
        }}
        color="gray.600"
        fontWeight="medium"
        textAlign="center"
        width="100%"
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export const RadioTab = <T extends FieldValues>({
  options,
  name,
  control,
  transform,
}: RadioTabProps<T>) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const transformedValue = transform?.input?.(value) ?? String(value);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "radio-tab",
    value: transformedValue,
    onChange: (newValue) => {
      const transformedNewValue = transform?.output?.(newValue) ?? newValue;
      onChange(transformedNewValue);
    },
  });

  const group = getRootProps();
  const width = `${100 / options.length}%`;

  return (
    <HStack
      {...group}
      spacing={0}
      width="100%"
      bg="blackAlpha.100"
      borderRadius="full"
      p={0}
    >
      {options.map((option) => {
        const radio = getRadioProps({ value: option.value });
        return (
          <RadioTabCard key={option.value} {...radio} width={width}>
            {option.label}
          </RadioTabCard>
        );
      })}
    </HStack>
  );
};
