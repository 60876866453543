import {
  Box,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { BiBookHeart } from "react-icons/bi";
import { ManageSurveyMenu } from "./ManageSurveyMenu";
import { ButtonLink } from "../../../components/ButtonLink";
import { format } from "date-fns";
import { useCurrentOrganization } from "../../../providers/CurrentOrganizationProvider";
import { Survey } from "../../../types/surveys";
import { Link as RouterLink } from "react-router-dom";
import { organizationSurveyShowUrl } from "../../../services/routes/urlBuilder";
import { useMySurveyResponse } from "../../../hooks/useMySurveyResponse";

interface SurveyItemProps {
  survey: Survey;
  onDelete?: () => void;
  canManage: boolean;
}

export const SurveyItem: React.FC<SurveyItemProps> = ({
  survey,
  onDelete,
  canManage,
}) => {
  const { organization } = useCurrentOrganization();
  const { surveyResponse } = useMySurveyResponse(survey.id);

  if (!organization) return null;

  return (
    <HStack>
      <LinkBox as={HStack}>
        <Icon as={BiBookHeart} />
        <Box>
          <LinkOverlay
            as={RouterLink}
            to={organizationSurveyShowUrl(organization.slug, survey.id)}
          >
            <Text size="md">{survey.title}</Text>
          </LinkOverlay>
          <Text size="sm" variant="secondary" marginTop={0}>
            {`Uploaded ${format(survey.created_at, "MM.dd.yyyy")}`}
          </Text>
        </Box>
      </LinkBox>
      <Spacer />
      {canManage ? (
        <ManageSurveyMenu
          organization={organization}
          survey={survey}
          onDelete={onDelete}
        />
      ) : !surveyResponse ? (
        <ButtonLink
          to={organizationSurveyShowUrl(organization.slug, survey.id)}
          buttonProps={{
            size: "sm",
            variant: "primary",
          }}
        >
          Complete
        </ButtonLink>
      ) : null}
    </HStack>
  );
};
