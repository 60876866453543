import { Control } from "react-hook-form/dist/types";
import { TicketTypeFormValues } from "../../types/ticket_types";
import { VianikoEvent } from "../../types/events";
import { Organization } from "../../types/organization";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Text,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { TEXT_SECONDARY_COLOR } from "../../services/theme/colors";
import { SurveyQuestionsFormSection } from "../surveys/forms/SurveyQuestionsFormSection";
import { useWatch } from "react-hook-form";

interface AdvancedConfigFormSectionProps {
  control: Control<TicketTypeFormValues>;
  ticketType: TicketTypeFormValues | null | undefined;
  event: VianikoEvent;
  organization: Organization;
}

export const AdvancedConfigFormSection: React.FC<
  AdvancedConfigFormSectionProps
> = ({ control, ticketType, event, organization }) => {
  // Watch the ticket_questions field to get the count
  const ticketQuestions = useWatch({
    control,
    name: "ticket_questions",
  });
  const checkinQuestions = useWatch({
    control,
    name: "checkin_questions",
  });

  const ticketQuestionsCount = ticketQuestions?.length || 0;
  const checkinQuestionsCount = checkinQuestions?.length || 0;

  return (
    <Accordion allowToggle>
      <AccordionItem>
        <Heading as="h3" size="sm">
          <AccordionButton>
            <HStack flex="1" textAlign="left" alignItems="baseline">
              <Heading size="md">Ticket questionnaire</Heading>
              {ticketQuestionsCount > 0 && (
                <Text color={TEXT_SECONDARY_COLOR}>
                  {`${ticketQuestionsCount} question${
                    ticketQuestionsCount !== 1 ? "s" : ""
                  }`}
                </Text>
              )}
            </HStack>
            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel>
          <VStack align="stretch" gap={2}>
            <Text size="md" color={TEXT_SECONDARY_COLOR}>
              Ask attendees anything - from dietaries to t-shirt size
            </Text>

            <SurveyQuestionsFormSection
              name="ticket_questions"
              enableEmptyQuestion={true}
            />

            <Text size="sm" color={TEXT_SECONDARY_COLOR}>
              Want to ask everybody the same question? You can create intake
              forms on the event page under ‘forms’
            </Text>
          </VStack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <Heading as="h3" size="sm">
          <AccordionButton>
            <HStack flex="1" textAlign="left" alignItems="baseline">
              <Heading size="md">Checkin questionnaire</Heading>
              {checkinQuestionsCount > 0 && (
                <Text color={TEXT_SECONDARY_COLOR}>
                  {`${checkinQuestionsCount} question${
                    checkinQuestionsCount !== 1 ? "s" : ""
                  }`}
                </Text>
              )}
            </HStack>

            <AccordionIcon />
          </AccordionButton>
        </Heading>
        <AccordionPanel>
          <VStack align="stretch" gap={2}>
            <Text color={TEXT_SECONDARY_COLOR}>
              Ask attendees at check-in - from dietaries to t-shirt size
            </Text>

            <SurveyQuestionsFormSection
              name="checkin_questions"
              enableEmptyQuestion={true}
            />
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
