import { useCallback, useEffect, useState } from "react";
import { fetchSurvey } from "../services/api/surveys";
import { Survey } from "../types/surveys";
import { useCurrentUser } from "../providers/CurrentUserProvider";

interface UseSurvey {
  survey: Survey | null;
  refetch: () => void;
}

export const useSurvey = (surveyId: string | undefined): UseSurvey => {
  const [survey, setSurvey] = useState<Survey | null>(null);
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    if (!surveyId || !currentUser) {
      setSurvey(null);
      return;
    }

    const survey = await fetchSurvey(surveyId);
    setSurvey(survey);
  }, [surveyId, currentUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { survey, refetch: fetch };
};
