import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface PageBreadcrumbProps {
  to: string;
  label: string;
  icon?: React.ReactNode;
  marginLeft?: number;
}

export const PageBreadcrumb: React.FC<PageBreadcrumbProps> = ({
  to,
  label,
  icon,
  marginLeft = 5,
}) => {
  return (
    <Breadcrumb
      separator={<ChevronRightIcon fontSize="18px" />}
      marginLeft={marginLeft}
      spacing={0.75}
    >
      <BreadcrumbItem>
        <BreadcrumbLink as={RouterLink} to={to} textDecor="none">
          <Flex direction={"row"} alignItems="center">
            {icon && (
              <Box marginRight={1} fontSize="18px">
                {icon}
              </Box>
            )}
            <Text
              textDecor={"underline"}
              style={{
                textUnderlineOffset: "0.2rem",
              }}
            >
              {label}
            </Text>
          </Flex>
        </BreadcrumbLink>
        <BreadcrumbSeparator />
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
