import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Heading,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { TicketTypeFormValues } from "../../types/ticket_types";
import { StyledRegisteredInput } from "../../components/forms/StyledRegisteredInput";
import { DollarsInput } from "../../components/forms/DollarsInput";
import { StyledTextarea } from "../../components/forms/StyledTextarea";
import {
  createTicketType,
  updateTicketType,
} from "../../services/api/ticketTypes";
import { Panel } from "../../components/Panel";
import { useSubEvents } from "../events/hooks/useSubEvents";
import { TicketTypePaymentType } from "./TicketTypePaymentType";
import { CURRENCY_CODE_TO_CURRENCY } from "../../services/currencies";
import { EstimatedFeesAndTaxesIndicator } from "../../components/FeesAndTaxesIndicator";
import { Organization } from "../../types/organization";
import { VianikoEvent } from "../../types/events";
import { OverrideTaxesAndFeesFormSection } from "../fees/OverrideTaxesAndFeesFormSection";
import { AdvancedConfigFormSection } from "./AdvancedConfigFormSection";

interface TicketTypeFormProps {
  event: VianikoEvent;
  organization: Organization;
  ticketType?: TicketTypeFormValues | null;
  onSubmit?: (data: TicketTypeFormValues) => void;
}

export const TicketTypeForm: React.FC<TicketTypeFormProps> = ({
  event,
  organization,
  ticketType,
  onSubmit: onSubmitProp,
}) => {
  const methods = useForm<TicketTypeFormValues>({
    defaultValues:
      ticketType ??
      ({
        id: "",
        name: "",
        overrides_fees_and_taxes: false,
        include_fees_in_prices: organization.include_fees_in_prices,
        include_taxes_in_prices: organization.include_taxes_in_prices,
        num_tax_basis_points: organization.num_tax_basis_points,
      } as TicketTypeFormValues),
    mode: "onBlur",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    control,
  } = methods;

  const paymentType = watch("payment_type");
  const hidden = watch("hidden");
  const priceInCents = watch("price_in_cents");

  const { subEvents } = useSubEvents(event.id);

  const onSubmit = async (data: TicketTypeFormValues) => {
    let ticketType;
    if (data.id) {
      ticketType = await updateTicketType(data);
    } else {
      ticketType = await createTicketType(data);
    }

    if (onSubmitProp) {
      onSubmitProp(ticketType);
    }
  };

  if (!event) return null;

  const minPriceInCents =
    CURRENCY_CODE_TO_CURRENCY[event.currency_iso_code].minPriceInCents;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Panel>
          <StyledRegisteredInput
            type="hidden"
            name="event_id"
            register={register}
            inputProps={{ value: event.id }}
          />

          <VStack gap={4}>
            <StyledRegisteredInput
              name="name"
              register={register}
              label="Name"
              options={{
                required: {
                  value: true,
                  message: "Enter a name for this ticket",
                },
              }}
              inputProps={{ placeholder: "General Admission" }}
              error={errors.name}
            />
            <StyledRegisteredInput
              name="capacity"
              type="number"
              register={register}
              label="Capacity"
            />

            <StyledTextarea
              name="description"
              register={register}
              label="Ticket details"
              error={errors.description}
              options={{
                maxLength: {
                  value: 2000,
                  message: "Description must be less than 2000 characters",
                },
              }}
            />

            <TicketTypePaymentType
              control={control}
              errors={errors}
              organizationId={event?.organization_id}
            />

            <>
              <HStack width="100%">
                <Checkbox {...register("hidden")} />
                <Text size="sm">Visible only with a code</Text>
                <Spacer />
              </HStack>

              {hidden && (
                <StyledRegisteredInput
                  type="text"
                  name="hidden_ticket_code"
                  register={register}
                  options={{
                    required: { value: true, message: "Enter a code" },
                  }}
                  error={errors.hidden_ticket_code}
                  label="Code"
                  inputProps={{
                    placeholder: "VIP20",
                  }}
                />
              )}
            </>

            {paymentType === "fixed" ? (
              <VStack width="100%" gap={4}>
                <HStack width="100%" align="end">
                  <DollarsInput
                    name="price_in_cents"
                    label="Price"
                    defaultValue={1000}
                    currencyIsoCode={event.currency_iso_code}
                    min={minPriceInCents}
                    control={control}
                    width="164px"
                  />
                  <Box height={7}>
                    <EstimatedFeesAndTaxesIndicator
                      currencyIsoCode={event.currency_iso_code}
                      priceInCents={priceInCents}
                      numTaxBasisPoints={watch("num_tax_basis_points")}
                      includeFeesInPrices={watch("include_fees_in_prices")}
                      includeTaxesInPrices={watch("include_taxes_in_prices")}
                    />
                  </Box>
                  <Spacer />
                </HStack>
                <OverrideTaxesAndFeesFormSection<TicketTypeFormValues>
                  currencyIsoCode={event.currency_iso_code}
                  control={control}
                  organization={organization}
                />
              </VStack>
            ) : paymentType === "slider" ? (
              <VStack gap={4} marginTop={4} width="100%">
                <HStack width="100%" align="end">
                  <DollarsInput
                    name="min_price_in_cents"
                    label="Minimum price"
                    defaultValue={1000}
                    currencyIsoCode={event.currency_iso_code}
                    min={minPriceInCents}
                    control={control}
                    width="164px"
                  />
                  <Box height={7}>
                    <EstimatedFeesAndTaxesIndicator
                      currencyIsoCode={event.currency_iso_code}
                      priceInCents={watch("min_price_in_cents") ?? 0}
                      numTaxBasisPoints={watch("num_tax_basis_points")}
                      includeFeesInPrices={watch("include_fees_in_prices")}
                      includeTaxesInPrices={watch("include_taxes_in_prices")}
                    />
                  </Box>
                </HStack>

                <HStack width="100%" align="end">
                  <DollarsInput
                    name="price_in_cents"
                    label="Default price"
                    defaultValue={1500}
                    currencyIsoCode={event.currency_iso_code}
                    min={minPriceInCents}
                    control={control}
                    width="164px"
                  />
                  <Box height={7}>
                    <EstimatedFeesAndTaxesIndicator
                      currencyIsoCode={event.currency_iso_code}
                      priceInCents={watch("price_in_cents") ?? 0}
                      numTaxBasisPoints={watch("num_tax_basis_points")}
                      includeFeesInPrices={watch("include_fees_in_prices")}
                      includeTaxesInPrices={watch("include_taxes_in_prices")}
                    />
                  </Box>
                </HStack>

                <HStack width="100%" align="end">
                  <DollarsInput
                    name="max_price_in_cents"
                    label="Maximum price"
                    defaultValue={2000}
                    currencyIsoCode={event.currency_iso_code}
                    min={minPriceInCents}
                    control={control}
                    width="164px"
                  />
                  <Box height={7}>
                    <EstimatedFeesAndTaxesIndicator
                      currencyIsoCode={event.currency_iso_code}
                      priceInCents={watch("max_price_in_cents") ?? 0}
                      numTaxBasisPoints={watch("num_tax_basis_points")}
                      includeFeesInPrices={watch("include_fees_in_prices")}
                      includeTaxesInPrices={watch("include_taxes_in_prices")}
                    />
                  </Box>
                </HStack>

                <OverrideTaxesAndFeesFormSection
                  control={control}
                  currencyIsoCode={event.currency_iso_code}
                  organization={organization}
                />
              </VStack>
            ) : null}
          </VStack>
        </Panel>

        {subEvents.length > 0 && (
          <Panel>
            <Heading as="h2" size="sm" marginBottom={2}>
              Ticket signs up user for
            </Heading>

            <CheckboxGroup defaultValue={ticketType?.auto_enroll_event_ids}>
              <VStack width="100%">
                {subEvents.map((event) => (
                  <HStack key={event.id} width="100%">
                    <Checkbox
                      value={event.id}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setValue("auto_enroll_event_ids", [
                            ...(getValues("auto_enroll_event_ids") || []),
                            event.id,
                          ]);
                        } else {
                          setValue(
                            "auto_enroll_event_ids",
                            (getValues("auto_enroll_event_ids") || []).filter(
                              (id) => id !== event.id
                            )
                          );
                        }
                      }}
                    />
                    <Text size="sm">{event.name}</Text>
                    <Spacer />
                  </HStack>
                ))}
              </VStack>
            </CheckboxGroup>
          </Panel>
        )}

        <AdvancedConfigFormSection
          control={control}
          ticketType={ticketType}
          event={event}
          organization={organization}
        />

        <Button
          variant="primary"
          width="100%"
          marginTop={6}
          marginBottom={12}
          type="submit"
        >
          Save
        </Button>
      </form>
    </FormProvider>
  );
};
