import { useFieldArray, useFormContext } from "react-hook-form";
import {
  QuestionsFormStateLocation,
  SurveyQuestion,
} from "../../../types/surveys";
import { BiPlus } from "react-icons/bi";
import { Button, Icon, VStack } from "@chakra-ui/react";
import { useCallback } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { isTouchDevice } from "../../../services/devices";
import { QuestionPanel } from "./QuestionPanel";

interface SurveyQuestionsFormSectionProps {
  name: QuestionsFormStateLocation;
  enableEmptyQuestion?: boolean;
}

export const SurveyQuestionsFormSection = ({
  name,
  enableEmptyQuestion = false,
}: SurveyQuestionsFormSectionProps) => {
  const { control } = useFormContext();
  const {
    fields: questions,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name,
  });

  const handleAddQuestion = useCallback(() => {
    const newQuestion: Partial<SurveyQuestion> = {
      question_type: "short_text",
      question_text: "",
      required: true,
      order_index: questions.length,
      survey_question_options: [],
    };
    append(newQuestion);
  }, [append, questions.length]);

  const moveQuestion = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      move(dragIndex, hoverIndex);
    },
    [move]
  );

  return (
    <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
      <VStack spacing={4}>
        {questions.map((question, index) => (
          <QuestionPanel
            key={question.id}
            field={question}
            index={index}
            remove={remove}
            moveQuestion={moveQuestion}
            name={`${name}.${index}`}
            canBeDeleted={enableEmptyQuestion || questions.length > 1}
          />
        ))}

        <Button variant="primary" size="lg" onClick={handleAddQuestion}>
          <Icon as={BiPlus} />
          Add Question
        </Button>
      </VStack>
    </DndProvider>
  );
};
