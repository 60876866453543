import { useState } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Portal,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { Pass } from "../../../../types/passes";

import { VianikoEvent } from "../../../../types/events";
import { useCurrentUserPass } from "../../../passes/hooks/useCurrentUserPass";
import { createTicketForPass } from "../../../../services/api/passes";
import { PassConfirmationModal } from "./PassConfirmationModal";
import { PurchasePassModal } from "../../../passes/components/PurchasePassModal";
import {
  eventShowUrl,
  passPaymentUrl,
} from "../../../../services/routes/urlBuilder";
import { Organization } from "../../../../types/organization";
import { Panel } from "../../../../components/Panel";
import { priceInDollars } from "../../../../services/payments";
import { FeesAndTaxesIndicator } from "../../../../components/FeesAndTaxesIndicator";
import { CompleteEventSurveysModals } from "../../checkin/surveys/CompleteEventSurveysModals";

interface EventPassItemProps {
  pass: Pass;
  event: VianikoEvent;
  organization: Organization;
  onComplete: () => void;
}

export const EventPassItem: React.FC<EventPassItemProps> = ({
  pass,
  event,
  organization,
  onComplete,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSurveyOpen, setIsSurveyOpen] = useState(false);

  const { userPass, ticket } = useCurrentUserPass(pass.id, event.id);

  const handleUsePass = async () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmUsePass = async () => {
    setIsConfirmationModalOpen(false);
    setIsSurveyOpen(true);
  };

  const handleBuyPass = () => {
    setIsConfirmationModalOpen(true);
  };

  const handleConfirmBuyPass = () => {
    setIsConfirmationModalOpen(false);
    setIsSurveyOpen(true);
  };

  const getWaiverCompleteCallback = async () => {
    if (userPass?.is_valid) {
      await createTicketForPass(pass.id, event.id);
    }

    onComplete();
  };

  return (
    <Panel>
      {userPass?.is_valid && isConfirmationModalOpen && (
        <Portal>
          <PassConfirmationModal
            pass={pass}
            userPass={userPass}
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onConfirm={handleConfirmUsePass}
          />
        </Portal>
      )}

      {!userPass?.is_valid && isConfirmationModalOpen && (
        <Portal>
          <PurchasePassModal
            passId={pass.id}
            isOpen={isConfirmationModalOpen}
            onClose={() => setIsConfirmationModalOpen(false)}
            onConfirm={handleConfirmBuyPass}
          />
        </Portal>
      )}

      {isSurveyOpen && (
        <Portal>
          <CompleteEventSurveysModals
            organization={organization}
            eventId={event.id}
            redirectTo={
              userPass?.is_valid
                ? eventShowUrl(event.id, {
                    invite: true,
                    welcome: true,
                  })
                : passPaymentUrl(pass.id, event.id)
            }
            onClose={() => setIsSurveyOpen(false)}
            onBeforeComplete={getWaiverCompleteCallback}
          />
        </Portal>
      )}

      <HStack>
        <Box>
          <Heading size="sm" as="h4">
            {pass.name}
          </Heading>
          <HStack>
            {userPass?.is_valid && userPass?.num_credits > 0 && (
              <Text size="md" marginLeft={"12px"}>
                {userPass?.num_credits} remaining
              </Text>
            )}
            {(!userPass || !userPass?.is_valid) && (
              <>
                <Text size="md" marginLeft={"12px"}>
                  {priceInDollars(event.currency_iso_code, pass.price_in_cents)}
                </Text>
                <FeesAndTaxesIndicator
                  currencyIsoCode={event.currency_iso_code}
                  feesInCents={pass.fees_in_cents}
                  taxesInCents={pass.taxes_in_cents}
                  includeFeesInPrices={pass.include_fees_in_prices}
                  includeTaxesInPrices={pass.include_taxes_in_prices}
                />
              </>
            )}
          </HStack>
        </Box>

        <Spacer />

        {!!ticket ? (
          <Button variant="primary" size="sm" isDisabled>
            Used
          </Button>
        ) : userPass?.is_valid ? (
          <Button variant="primary" size="sm" onClick={handleUsePass}>
            Use
          </Button>
        ) : (
          <Button variant="primary" size="sm" onClick={handleBuyPass}>
            Buy
          </Button>
        )}
      </HStack>
    </Panel>
  );
};
