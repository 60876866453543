import axiosInstance from "./axiosInstance";

export const getAccount = async (organizationId: string) => {
  const result = await axiosInstance.get(`/payments/${organizationId}/account`);
  return result.data;
};

export const createAccount = async (organizationId: string) => {
  const result = await axiosInstance.post("/payments/account", {
    organization_id: organizationId,
  });
  return result.data;
};

export const accountSession = async (organizationId: string) => {
  const result = await axiosInstance.post("/payments/account-session", {
    organization_id: organizationId,
  });
  return result.data;
};

interface PaymentIntentOption {
  ticket_type_id: string;
  quantity: number;
  amount_in_cents?: number;
}

export const createPaymentIntent = async (
  eventId: string,
  options: PaymentIntentOption[]
) => {
  const result = await axiosInstance.post(
    `/payments/events/${eventId}/create-payment-intent`,
    options
  );
  return result.data;
};

export const createPassPaymentIntent = async (
  passId: string,
  body?: { eventIdToEnroll?: string }
) => {
  const result = await axiosInstance.post(
    `/payments/passes/${passId}/create-payment-intent`,
    body
  );
  return result.data;
};
