import {
  Box,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { BiBookHeart } from "react-icons/bi";
import { ManageSurveyMenu } from "./ManageSurveyMenu";
import { ButtonLink } from "../../../../components/ButtonLink";
import { format } from "date-fns";
import { Survey } from "../../../../types/surveys";
import { Link as RouterLink } from "react-router-dom";
import {
  eventSurveyShowUrl,
  organizationSurveyShowUrl,
} from "../../../../services/routes/urlBuilder";
import { useMySurveyResponse } from "../../../../hooks/useMySurveyResponse";
import { VianikoEvent } from "../../../../types/events";
import { Organization } from "../../../../types/organization";
import { TEXT_SECONDARY_COLOR } from "../../../../services/theme/colors";

interface SurveysListItemProps {
  survey: Survey;
  event: VianikoEvent;
  organization: Organization;
  canManage: boolean;
  isEnabled: boolean;
}

export const SurveysListItem: React.FC<SurveysListItemProps> = ({
  survey,
  event,
  organization,
  canManage,
  isEnabled,
}) => {
  const { surveyResponse } = useMySurveyResponse(survey.id);

  return (
    <HStack>
      <LinkBox as={HStack} color={isEnabled ? "" : TEXT_SECONDARY_COLOR}>
        <Icon as={BiBookHeart} />
        <Box>
          <LinkOverlay
            as={RouterLink}
            to={eventSurveyShowUrl(event.id, survey.id)}
          >
            <Text size="md" variant={isEnabled ? "" : "secondary"}>
              {survey.title}
            </Text>
          </LinkOverlay>
          <Text size="sm" variant="secondary" marginTop={0}>
            {`Uploaded ${format(survey.created_at, "MM.dd.yyyy")}`}
          </Text>
        </Box>
      </LinkBox>
      <Spacer />
      {canManage ? (
        <ManageSurveyMenu
          organization={organization}
          event={event}
          survey={survey}
          isEnabled={isEnabled}
        />
      ) : !surveyResponse ? (
        <ButtonLink
          to={organizationSurveyShowUrl(organization.slug, survey.id)}
          buttonProps={{
            size: "sm",
            variant: "primary",
          }}
        >
          Complete
        </ButtonLink>
      ) : null}
    </HStack>
  );
};
