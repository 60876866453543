import React from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useSurvey } from "../../hooks/useSurvey";
import {
  eventShowUrl,
  homeUrl,
  organizationSurveysUrl,
} from "../../services/routes/urlBuilder";
import { useOrganization } from "../../providers/CurrentOrganizationProvider";
import { ShowSurveyModal } from "./ShowSurveyModal";

export const ShowSurveyPage: React.FC = () => {
  const { eventId, surveyId } = useParams<{
    eventId?: string;
    surveyId: string;
  }>();

  const navigate = useNavigate();
  const { survey } = useSurvey(surveyId);
  const { organization } = useOrganization(survey?.organization_id);

  const onClose = async () => {
    if (eventId) {
      navigate(eventShowUrl(eventId));
    } else if (organization?.slug) {
      window.location.href = organizationSurveysUrl(organization.slug);
    } else {
      navigate(homeUrl());
    }
  };

  return survey && organization?.id ? (
    <ShowSurveyModal
      isOpen={true}
      onClose={onClose}
      survey={survey}
      eventId={eventId}
      organizationId={organization.id}
      onComplete={onClose}
    />
  ) : null;
};
