export const homeUrl = () => "/";
export const welcomeUrl = () => "/welcome";

interface AuthOptions {
  authReason?: "invite" | "ticket_purchase";
}

export const authUrl = (redirectTo?: string, options?: AuthOptions) => {
  const { authReason } = options || {};

  const urlParams = new URLSearchParams({
    redirect: redirectTo ? encodeURIComponent(redirectTo) : homeUrl(),
    authReason: authReason || "",
  });

  return `/auth?${urlParams.toString()}`;
};

export const logoutUrl = () => {
  return "/logout";
};

interface UserShowUrlParams {
  tab?: "events" | "friends";
  eventsDirection?: "future";
}
export const userShowUrl = (userId: string, params?: UserShowUrlParams) => {
  const { tab, eventsDirection } = params || {};
  const urlParams = params
    ? new URLSearchParams({
        tab: tab ? tab.toString() : "",
        eventsDirection: eventsDirection ? eventsDirection.toString() : "",
      })
    : "";

  return `/users/${userId}?${urlParams.toString()}`;
};

export const userEditUrl = (
  userId: string,
  redirectAfter?: string,
  createProfileAfterAuth?: boolean
) => {
  const urlParamData: any = {};
  if (redirectAfter && redirectAfter !== "")
    urlParamData["redirectAfter"] = encodeURIComponent(redirectAfter);
  if (createProfileAfterAuth) urlParamData["createProfileAfterAuth"] = "yes";

  const urlParams = new URLSearchParams(urlParamData);

  return `/users/${userId}/edit?${urlParams.toString()}`;
};

interface EventShowUrlParams {
  invite?: boolean;
  welcome?: boolean;
  ticket_selection?: boolean;
  ticket_types?: {
    [key: string]: number;
  };
}

export const eventShowUrl = (eventId: string, params?: EventShowUrlParams) => {
  const { invite, welcome, ticket_selection, ticket_types } = params || {};
  const paramsObject: { [key: string]: string } = {};

  if (invite) paramsObject["invite"] = invite.toString();
  if (welcome) paramsObject["welcome"] = welcome.toString();
  if (ticket_selection)
    paramsObject["ticket_selection"] = ticket_selection.toString();
  if (ticket_types)
    paramsObject["ticket_types"] = encodeURIComponent(
      JSON.stringify(ticket_types)
    );

  const urlParams =
    Object.keys(paramsObject).length > 0
      ? new URLSearchParams(paramsObject)
      : "";

  return `/events/${eventId}?${urlParams.toString()}`;
};

export const eventAttendeesUrl = (eventId: string) =>
  `/events/${eventId}/attendees`;
export const eventProgramUrl = (eventId: string) =>
  `/events/${eventId}/program`;
export const eventTicketsUrl = (eventId: string) =>
  `/events/${eventId}/tickets`;
export const eventMessagesUrl = (eventId: string) =>
  `/events/${eventId}/messages`;
export const eventSurveysUrl = (eventId: string) =>
  `/events/${eventId}/surveys`;

export const eventSignAllSurveysUrl = (
  eventId: string,
  params: { redirectTo: string | null }
) => {
  const { redirectTo } = params;
  const urlParams = new URLSearchParams();
  if (redirectTo) urlParams.set("redirectTo", redirectTo);

  return `/events/${eventId}/surveys/all?${urlParams.toString()}`;
};

export const eventEditUrl = (eventId: string) => `/events/${eventId}/edit`;
interface EventNewUrlParams {
  slug: string;
  parentEventId?: string;
}

export const eventNewUrl = (params?: EventNewUrlParams) => {
  const { slug, parentEventId } = params || {};

  const urlParams = new URLSearchParams({
    parentEventId: parentEventId || "",
  });

  return `${slug ? `/o/${slug}` : ""}/events/new?${urlParams.toString()}`;
};

export const ticketTypeNewUrl = (eventId: string) => {
  return `/events/${eventId}/ticket_types/new`;
};

export const ticketTypeEditUrl = (eventId: string, ticketTypeId: string) => {
  return `/events/${eventId}/ticket_types/${ticketTypeId}/edit`;
};

interface EventPaymentUrlParams {
  amountInCents?: string;
  paymentToken?: string;
}
export const eventPaymentUrl = (
  eventId: string,
  params?: EventPaymentUrlParams
) =>
  `/events/${eventId}/payments/new${
    params
      ? `?amountInCents=${params.amountInCents}&paymentToken=${params.paymentToken}`
      : ""
  }`;

interface PaymentsRedirectorOptions {
  amountInCents?: string;
  paymentToken?: string;
}
export const paymentsRedirectorUrl = (
  eventId: string,
  options?: PaymentsRedirectorOptions
) => {
  const { amountInCents, paymentToken } = options || {};

  let paramsObject: { [key: string]: string } = {};

  if (amountInCents) paramsObject["amountInCents"] = amountInCents;
  if (paymentToken) paramsObject["paymentToken"] = paymentToken;

  return `/events/${eventId}/payments?${
    options ? new URLSearchParams(paramsObject).toString() : ""
  }`;
};

export const eventCheckinUrl = (eventId: string) =>
  `/events/${eventId}/checkin`;
export const eventScanUrl = (eventId: string) =>
  `/events/${eventId}/checkin/scan`;

export const recurringEventShowUrl = (recurringEventId: string) =>
  `/recurring_events/${recurringEventId}`;
export const recurringEventEditUrl = (recurringEventId: string) =>
  `/recurring_events/${recurringEventId}/edit`;

export const organizationShowUrl = (slug: string) => `/o/${slug}`;
export const organizationInfoUrl = (slug: string) => `/o/${slug}/info`;
export const organizationMembersUrl = (slug: string) => `/o/${slug}/members`;
export const organizationPassesUrl = (slug: string) => `/o/${slug}/passes`;
export const organizationSurveysUrl = (slug: string) => `/o/${slug}/surveys`;

export const organizationEditUrl = (slug: string) => `/o/${slug}/edit`;

export const surveyNewUrl = (slug: string) => `/o/${slug}/surveys/new`;
export const surveyEditUrl = (slug: string, surveyId: string) =>
  `/o/${slug}/surveys/${surveyId}/edit`;

export const organizationSurveyShowUrl = (slug: string, surveyId: string) =>
  `/o/${slug}/surveys/${surveyId}`;
export const eventSurveyShowUrl = (eventId: string, surveyId: string) =>
  `/events/${eventId}/surveys/${surveyId}`;

export const passNewUrl = (slug: string) => `/o/${slug}/passes/new`;
export const passEditUrl = (slug: string, passId: string) =>
  `/o/${slug}/passes/${passId}/edit`;

export const passPaymentUrl = (passId: string, eventIdToEnroll?: string) =>
  `/passes/${passId}/payments/new${
    eventIdToEnroll ? `?eventIdToEnroll=${eventIdToEnroll}` : ""
  }`;
export const passPostPaymentUrl = (passId: string, eventIdToEnroll?: string) =>
  `/passes/${passId}/payments/post?eventIdToEnroll=${eventIdToEnroll}`;

export const adminManageOrganizationUsersUrl = (slug: string) =>
  `/admin/o/${slug}/manage_users`;
export const adminManageOrganizationPayoutUrl = (slug: string) =>
  `/admin/o/${slug}/manage-payout`;
export const adminManageOrganizationRefundsUrl = (slug: string) =>
  `/admin/o/${slug}/manage-tickets`;
