import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { Survey, SurveyQuestion } from "../../../types/surveys";
import { StyledRegisteredInput } from "../../../components/forms/StyledRegisteredInput";
import { SurveyQuestionsFormSection } from "./SurveyQuestionsFormSection";
import { RadioTab } from "../../../components/forms/RadioTab";
import { CompletedByCheckboxes } from "./CompletedByCheckboxes";
import { useParams } from "react-router-dom";
import { useOrganization } from "../../../providers/CurrentOrganizationProvider";
import { useEffect } from "react";
import { Panel } from "../../../components/Panel";
import { PageBreadcrumb } from "../../../components/PageBreadcrumb";
import {
  homeUrl,
  organizationShowUrl,
} from "../../../services/routes/urlBuilder";

interface SurveyFormProps {
  onSubmit: (data: Survey) => Promise<void>;
  defaultValues?: Survey;
}

const DEFAULT_NEW_QUESTION: Partial<SurveyQuestion> = {
  question_type: "waiver",
  question_text: "",
  required: true,
  order_index: 0,
  survey_question_options: [],
};

export const SurveyForm: React.FC<SurveyFormProps> = ({
  onSubmit: onSubmitProp,
  defaultValues,
}) => {
  const { organizationId: organizationIdParam } = useParams<{
    organizationId: string;
  }>();
  const { organization } = useOrganization(organizationIdParam);
  const organizationId =
    defaultValues?.organization_id ?? organization?.id ?? null;

  const methods = useForm<Survey>({
    defaultValues: {
      ...defaultValues,
      title: defaultValues?.title ?? "",
      description: defaultValues?.description ?? "",
      survey_questions: defaultValues?.survey_questions ?? [
        { ...DEFAULT_NEW_QUESTION },
      ],
      should_respond_each_event:
        defaultValues?.should_respond_each_event ?? false,
      applies_to_all_events: defaultValues?.applies_to_all_events ?? true,
      organization_id: organizationId ?? "",
      event_ids: defaultValues?.event_ids ?? [],
    },
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  // Update form values when organization changes
  useEffect(() => {
    if (organization?.id && !defaultValues?.organization_id) {
      methods.reset({
        ...methods.getValues(),
        organization_id: organization.id,
      });
    }
  }, [organization, defaultValues, methods]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = methods;

  useEffect(() => {
    if (organizationId) {
      setValue("organization_id", organizationId);
    }
  }, [organizationId, setValue]);

  const onSubmit = async (data: Survey) => {
    if (!data.organization_id) {
      throw new Error("Survey submitted without organization_id");
    }

    await onSubmitProp(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("organization_id")} />

        <VStack align="stretch">
          {organization ? (
            <PageBreadcrumb
              to={organizationShowUrl(organization.slug)}
              label={organization.name}
            />
          ) : (
            <PageBreadcrumb to={homeUrl()} label="Home" />
          )}
          <Panel>
            <VStack align="stretch" gap={3}>
              <Heading size="xl" as="h1">
                Form details
              </Heading>

              <StyledRegisteredInput
                name="title"
                inputProps={{
                  placeholder: "Form name e.g. 'Liability waiver'",
                }}
                type="text"
                register={register}
                options={{ required: "This field is required" }}
                error={errors.title}
              />

              <SurveyQuestionsFormSection name="survey_questions" />

              <Text as="label" htmlFor="should_respond_each_event">
                Attendees complete intake form:
              </Text>
              <RadioTab
                options={[
                  { value: "false", label: "Once" },
                  { value: "true", label: "With every event" },
                ]}
                name="should_respond_each_event"
                control={control}
                transform={{
                  input: (value: boolean) => String(value),
                  output: (value: string) => value === "true",
                }}
              />

              {organization && (
                <CompletedByCheckboxes
                  control={control}
                  organizationId={organization.id}
                />
              )}

              <Button type="submit" variant="primary" size="lg" width="100%">
                Save
              </Button>
            </VStack>
          </Panel>
        </VStack>
      </form>
    </FormProvider>
  );
};
