import { SurveyForm } from "./forms/Form";
import { Survey } from "../../types/surveys";
import { createSurvey } from "../../services/api/surveys";
import { useNavigate, useParams } from "react-router-dom";
import { organizationSurveysUrl } from "../../services/routes/urlBuilder";

export const NewSurveyPage: React.FC = () => {
  const navigate = useNavigate();
  const { organizationId } = useParams<{ organizationId: string }>();

  const handleSubmit = async (survey: Survey) => {
    await createSurvey(survey);
    if (organizationId) {
      navigate(organizationSurveysUrl(organizationId));
    }
  };

  return <SurveyForm onSubmit={handleSubmit} />;
};
