import { useCallback, useEffect, useState } from "react";
import { useCurrentUser } from "../providers/CurrentUserProvider";
import { SurveyResponse } from "../types/survey_responses";
import { fetchSurveyResponse } from "../services/api/surveys";

interface UseMySurveyResponse {
  surveyResponse: SurveyResponse | null;
  refetch: () => Promise<void>;
  loaded: boolean;
}

export const useMySurveyResponse = (
  surveyId: string | undefined
): UseMySurveyResponse => {
  const [surveyResponse, setSurveyResponse] = useState<SurveyResponse | null>(
    null
  );
  const [loaded, setLoaded] = useState(false);
  const { currentUser } = useCurrentUser();

  const fetch = useCallback(async () => {
    setLoaded(false);
    if (!surveyId) return;
    const surveyResponse = await fetchSurveyResponse(surveyId);
    setSurveyResponse(surveyResponse);
    setLoaded(true);
  }, [surveyId]);

  useEffect(() => {
    if (!currentUser) return;
    fetch();
  }, [fetch, currentUser]);

  return { surveyResponse, refetch: fetch, loaded };
};
