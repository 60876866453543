import React from "react";
import { SurveyQuestion } from "../../types/surveys";
import {
  FormControl,
  FormErrorMessage,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Stack,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import { Control, useController, UseFormRegister } from "react-hook-form";
import { SurveyFormSubmission } from "../../types/survey_responses";
import { StyledTextarea } from "../forms/StyledTextarea";
import { StyledRegisteredInput } from "../forms/StyledRegisteredInput";

interface SurveyResponseQuestionProps {
  question: Partial<SurveyQuestion>;
  control: Control<SurveyFormSubmission>;
  register: UseFormRegister<SurveyFormSubmission>;
  name: `survey_question_answers.${number}`;
  error: any;
}

export const SurveyResponseQuestion: React.FC<SurveyResponseQuestionProps> = ({
  question,
  control,
  register,
  name,
  error,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
    defaultValue: {
      question_id: question.id || "",
    },
    rules: question.required
      ? {
          validate: (value) => {
            if (
              question.question_type === "multiple_choice" ||
              question.question_type === "multiple_choice_checkbox"
            ) {
              return (
                (!!value?.selected_options &&
                  value?.selected_options?.length > 0) ||
                "Please select an option"
              );
            }
            if (question.question_type === "waiver") {
              return !!value?.text_answer || "This field is required";
            }
            return !!value?.text_answer || "This field is required";
          },
        }
      : undefined,
  });

  const handleOptionSelect = (optionId: string) => {
    onChange({ ...value, selected_options: [{ option_id: optionId }] });
  };

  const handleCheckboxChange = (optionIds: string[]) => {
    onChange({
      ...value,
      selected_options: optionIds.map((id) => ({ option_id: id })),
    });
  };

  const renderQuestionInput = () => {
    switch (question.question_type) {
      case "short_text":
        return (
          <StyledRegisteredInput
            name={`${name}.text_answer`}
            register={register}
            label={question.question_text}
            options={{ required: question.required }}
            error={error}
          />
        );
      case "long_text":
        return (
          <StyledTextarea
            name={`${name}.text_answer`}
            register={register}
            label={question.question_text}
            options={{ required: question.required }}
            error={error}
          />
        );
      case "multiple_choice":
        return (
          <>
            <FormLabel>{question.question_text}</FormLabel>
            <RadioGroup
              value={value?.selected_options?.[0]?.option_id || ""}
              onChange={handleOptionSelect}
            >
              <Stack>
                {question?.survey_question_options?.map((option) => (
                  <Radio key={option.id} value={option.id || ""}>
                    {option.option_text}
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
          </>
        );
      case "multiple_choice_checkbox":
        return (
          <>
            <FormLabel>{question.question_text}</FormLabel>
            <CheckboxGroup
              value={value?.selected_options?.map((opt) => opt.option_id || "")}
              onChange={handleCheckboxChange}
            >
              <Stack>
                {question?.survey_question_options?.map((option) => (
                  <Checkbox key={option.id} value={option.id || ""}>
                    {option.option_text}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
          </>
        );
      case "waiver":
        return (
          <>
            <Text whiteSpace="pre-wrap">{question.question_text}</Text>
            <Checkbox
              name={`${name}.text_answer`}
              isChecked={value.text_answer === "checked"}
              onChange={(e) =>
                onChange({
                  ...value,
                  text_answer: e.target.checked ? "checked" : "",
                })
              }
            >
              I accept the waiver
            </Checkbox>
            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
          </>
        );
      default:
        return null;
    }
  };

  return <FormControl isInvalid={!!error}>{renderQuestionInput()}</FormControl>;
};
