import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { SurveyResponseForm } from "../../components/surveys/SurveyResponseForm";
import { useMySurveyResponse } from "../../hooks/useMySurveyResponse";
import { SurveyFormSubmission } from "../../types/survey_responses";
import { Survey } from "../../types/surveys";

interface ShowSurveyModalProps {
  survey: Survey;
  eventId?: string;
  organizationId: string;
  isOpen: boolean;
  onClose: () => void;
  onComplete: (data: SurveyFormSubmission) => void;
}

export const ShowSurveyModal: React.FC<ShowSurveyModalProps> = ({
  survey,
  eventId,
  organizationId,
  isOpen,
  onClose,
  onComplete,
}) => {
  const { surveyResponse, loaded: responseLoaded } = useMySurveyResponse(
    survey.id
  );

  const handleSubmit = (data: SurveyFormSubmission) => {
    onComplete(data);
  };

  if (!responseLoaded) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={6}>
          {survey && responseLoaded && (
            <SurveyResponseForm
              survey={survey}
              eventId={eventId}
              organizationId={organizationId}
              afterSubmit={handleSubmit}
              surveyResponse={surveyResponse}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
