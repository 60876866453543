import { useCallback, useEffect, useState } from "react";
import { fetchOrganizationSurveys } from "../services/api/surveys";
import { Survey } from "../types/surveys";
import { useOrganizationUser } from "../providers/CurrentOrganizationUserProvider";

interface UseOrganizationSurveys {
  surveys: Survey[];
  refetch: () => void;
}

export const useOrganizationSurveys = (
  organizationId: string | undefined
): UseOrganizationSurveys => {
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const { organizationUser } = useOrganizationUser(organizationId);

  const fetch = useCallback(async () => {
    if (!organizationId || !organizationUser) {
      return;
    }

    const surveys = await fetchOrganizationSurveys(organizationId);
    setSurveys(surveys);
  }, [organizationId, organizationUser]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { surveys, refetch: fetch };
};
