import { Outlet, createBrowserRouter } from "react-router-dom";
import { PhoneAuth } from "../../pages/auth/PhoneAuth";
import { Root } from "../../pages/root";
import { OrganizationShowPage } from "../../pages/organizations/Show";
import { EventShowPage } from "../../pages/events/show";
import { EventPaymentPage } from "../../pages/payments/EventPayment";
import { ErrorPage } from "../../pages/errors";
import { AuthenticatedRoutes } from "./AuthenticatedRoutes";
import { UserShowPage } from "../../pages/users/Show";
import { UserEditPage } from "../../pages/users/Edit";
import { NewEventPage } from "../../pages/events/New";
import { OrganizationParentPage } from "../../pages/organizations/OrganizationParentPage";
import { EditEventPage } from "../../pages/events/Edit";
import { NewOrganization } from "../../pages/organizations/New";
import { EditOrganization } from "../../pages/organizations/Edit";
import { EditRecurringEvent } from "../../pages/recurring_events/EditRecurringEvent";
import { EventParentPage } from "../../pages/events/components/EventParentPage";
import { PaymentAccount } from "../../pages/organizations/settings/PaymentAccount";
import { OrganizationCalendar } from "../../pages/organizations/components/OrganizationCalendar";
import { EventPaymentRedirector } from "../../pages/payments/EventPaymentRedirector";
import { Logout } from "../../pages/auth/Logout";
import { NewTicketTypePage } from "../../pages/ticket_types/NewTicketTypePage";
import { EditTicketTypePage } from "../../pages/ticket_types/EditTicketTypePage";
import { EventCheckinList } from "../../pages/events/checkin/EventCheckinList";
import { EventCheckinScan } from "../../pages/events/checkin/EventCheckinScan";
import { EventCheckInParent } from "../../pages/events/checkin/EventCheckInParent";
import { OrganizationAdminWrapper } from "../../pages/organizations/settings/OrganizationAdminWrapper";
import { ManageOrganizationUsers } from "../../pages/organizations/settings/ManageOrganizationUsers";
import { AttendeesTab } from "../../pages/events/show/attendees";
import { Programs } from "../../pages/events/show/programs";
import { TicketsTab } from "../../pages/events/show/tickets";
import { MessagesTab } from "../../pages/events/show/messages";
import { SurveysTab } from "../../pages/events/show/surveys";
import { EventInfo } from "../../pages/events/show/info";
import { NewPass } from "../../pages/passes/NewPass";
import { EditPass } from "../../pages/passes/EditPass";
import { NewPassPaymentPage } from "../../pages/payments/NewPassPaymentPage";
import { HomeRedirector } from "../../pages/home/HomeRedirector";
import { LandingPage } from "../../pages/home/Landing";
import { SurveysList } from "../../pages/organizations/components/SurveysList";
import { PassesTab } from "../../pages/organizations/components/PassesTab";
import { OrganizationMembers } from "../../pages/organizations/components/OrganizationMembers";
import { OrganizationInfo } from "../../pages/organizations/components/OrganizationInfo";
import { ManageTickets } from "../../pages/organizations/settings/tickets/ManageTickets";
import { OrganizationCalendarEmbed } from "../../pages/organizations/components/OrganizationCalendarEmbed";
import { OldOrganizationsRedirect } from "./OldOrganizationsRedirect";
import { NewSurveyPage } from "../../pages/surveys/New";
import { EditSurveyPage } from "../../pages/surveys/Edit";
import { ShowSurveyPage } from "../../pages/surveys/Show";
import { CompleteEventSurveys } from "../../pages/events/checkin/surveys/CompleteEventSurveys";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <HomeRedirector />,
      },
      {
        path: "/auth",
        element: <PhoneAuth />,
      },
      {
        path: "/logout",
        element: <Logout />,
      },
      {
        path: "/events/:eventId",
        element: <EventParentPage />,
        children: [
          {
            path: "",
            element: <EventShowPage />,
            children: [
              {
                path: "",
                element: <EventInfo />,
              },
              { path: "attendees", element: <AttendeesTab /> },
              { path: "program", element: <Programs /> },
              { path: "tickets", element: <TicketsTab /> },
              { path: "messages", element: <MessagesTab /> },
              { path: "surveys", element: <SurveysTab /> },
              { path: "surveys/all", element: <CompleteEventSurveys /> },
              { path: "surveys/:surveyId", element: <ShowSurveyPage /> },
              {
                path: "passes/:passId",
              },
            ],
          },
        ],
      },
      {
        path: "organizations/:organizationId/*",
        element: <OldOrganizationsRedirect />,
      },
      {
        path: "/o/:organizationId",
        element: <OrganizationParentPage />,
        children: [
          {
            path: "",
            element: <OrganizationShowPage />,
            children: [
              { path: "", index: true, element: <OrganizationCalendar /> },
              {
                path: "info",
                element: <OrganizationInfo />,
              },
              {
                path: "members",
                element: <OrganizationMembers />,
              },
              {
                path: "passes",
                element: <PassesTab />,
              },
              {
                path: "surveys",
                element: <SurveysList />,
                children: [
                  {
                    path: ":surveyId",
                    element: <ShowSurveyPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <AuthenticatedRoutes />,
        children: [
          {
            path: "/events/new",
            element: <NewEventPage />,
          },
          {
            path: "/events/:eventId",
            element: <EventParentPage />,
            children: [
              {
                path: "edit",
                element: <EditEventPage />,
              },
              {
                path: "payments",
                element: <EventPaymentRedirector />,
                children: [
                  {
                    path: "new",
                    element: <EventPaymentPage />,
                  },
                ],
              },
              {
                path: "ticket_types",
                children: [
                  {
                    path: "new",
                    element: <NewTicketTypePage />,
                  },
                  {
                    path: ":ticketTypeId/edit",
                    element: <EditTicketTypePage />,
                  },
                ],
              },
              {
                path: "checkin",
                element: <EventCheckInParent />,
                children: [
                  {
                    path: "",
                    element: <EventCheckinList />,
                  },
                  {
                    path: "scan",
                    element: <EventCheckinScan />,
                  },
                ],
              },
            ],
          },
          {
            path: "passes",
            children: [
              {
                path: ":passId/payments",
                children: [
                  {
                    path: "new",
                    element: <NewPassPaymentPage />,
                  },
                ],
              },
            ],
          },
          {
            path: "/recurring_events/:id/edit",
            element: <EditRecurringEvent />,
          },
          {
            path: "organizations/new",
            element: <NewOrganization />,
          },
          {
            path: "/o/:organizationId",
            element: <OrganizationParentPage />,
            children: [
              { path: "edit", element: <EditOrganization /> },
              {
                path: "events/new",
                element: <NewEventPage />,
              },
              {
                path: "surveys",
                children: [
                  {
                    path: "new",
                    element: <NewSurveyPage />,
                  },
                  {
                    path: ":surveyId/edit",
                    element: <EditSurveyPage />,
                  },
                ],
              },
              {
                path: "passes",
                children: [
                  {
                    path: "new",
                    element: <NewPass />,
                  },
                  {
                    path: ":passId/edit",
                    element: <EditPass />,
                  },
                ],
              },
            ],
          },
          {
            path: "/users/:id",
            element: <UserShowPage />,
            children: [{ path: "friends" }],
          },
          {
            path: "/users/:id/edit",
            element: <UserEditPage />,
          },
        ],
      },
    ],
  },

  {
    path: "/admin",
    element: <Root containerSize="md" />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "o/:organizationId",
        element: <OrganizationParentPage />,
        children: [
          {
            path: "",
            element: <OrganizationAdminWrapper />,
            children: [
              {
                path: "manage_users",
                element: <ManageOrganizationUsers />,
              },
              {
                path: "manage-payout",
                element: <PaymentAccount />,
              },
              {
                path: "manage-tickets",
                element: <ManageTickets />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    element: <Root containerSize="lg" />,
    children: [
      {
        path: "/welcome",
        element: <LandingPage />,
      },
    ],
  },
  {
    path: "/embed",
    element: <Outlet />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "organizations/:organizationId",
        element: <OrganizationParentPage />,
        children: [
          {
            path: "calendar",
            element: <OrganizationCalendarEmbed />,
          },
        ],
      },
    ],
  },
]);
