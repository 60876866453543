import { Box, StyleProps } from "@chakra-ui/react";
import { forwardRef } from "react";

interface PanelProps extends StyleProps {
  children: React.ReactNode;
}

export const Panel = forwardRef<HTMLDivElement, PanelProps>(
  ({ children, ...styleProps }, ref) => {
    return (
      <Box
        bg="red"
        borderRadius="24px"
        padding={5}
        marginY={1}
        background="rgba(255, 255, 255, 0.4)"
        ref={ref}
        {...styleProps}
      >
        {children}
      </Box>
    );
  }
);
