import { useController, get, useFormContext } from "react-hook-form";
import { Checkbox, HStack, VStack } from "@chakra-ui/react";
import { StyledRegisteredInput } from "../../../components/forms/StyledRegisteredInput";
import { StyledTextarea } from "../../../components/forms/StyledTextarea";
import { MultipleChoiceOptions } from "./MultipleChoiceOptions";
import { QuestionsFormStateLocation } from "../../../types/surveys";

interface QuestionTypeFormSectionProps {
  name: `${QuestionsFormStateLocation}.${number}`;
}

export const QuestionTypeFormSection: React.FC<
  QuestionTypeFormSectionProps
> = ({ name }) => {
  const { control } = useFormContext();
  const {
    field: { value: questionType },
    formState: { errors, touchedFields },
  } = useController({
    control,
    name: `${name}.question_type`,
  });

  const error = get(errors, `${name}.question_text`);
  const isTouched = get(touchedFields, `${name}.question_text`);
  const showError = error && isTouched;

  return (
    <VStack spacing={4} align="stretch">
      {questionType === "waiver" ? (
        <StyledTextarea
          name={`${name}.question_text`}
          textareaProps={{
            placeholder: "Enter waiver text",
            rows: 6,
          }}
          register={control.register}
          options={{ required: "Waiver text is required" }}
          error={showError ? error : undefined}
        />
      ) : (
        <StyledRegisteredInput
          name={`${name}.question_text`}
          inputProps={{
            placeholder: "Enter your question",
          }}
          type="text"
          register={control.register}
          options={{ required: "Question text is required" }}
          error={showError ? error : undefined}
        />
      )}

      {questionType === "multiple_choice" ||
      questionType === "multiple_choice_checkbox" ? (
        <MultipleChoiceOptions name={name} />
      ) : null}

      <HStack width="100%">
        <Checkbox {...control.register(`${name}.required`)}>Required</Checkbox>
      </HStack>
    </VStack>
  );
};
