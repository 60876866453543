import { Organization } from "../../../../types/organization";
import { ShowSurveyModal } from "../../../surveys/ShowSurveyModal";
import { useEffect, useState } from "react";
import { useCurrentUser } from "../../../../providers/CurrentUserProvider";
import { useNavigate } from "react-router-dom";
import {
  authUrl,
  eventSignAllSurveysUrl,
} from "../../../../services/routes/urlBuilder";
import { SurveyFormSubmission } from "../../../../types/survey_responses";
import { useMyEventSurveys } from "../../../../hooks/useMyEventSurveys";

interface CompleteEventSurveysModalsProps {
  organization: Organization;
  eventId: string;
  redirectTo: string | null;
  onClose?: () => void;
  onBeforeComplete?: () => Promise<void>;
}

export const CompleteEventSurveysModals: React.FC<
  CompleteEventSurveysModalsProps
> = ({ organization, eventId, redirectTo, onClose, onBeforeComplete }) => {
  const { currentUser, initialized: userInitialized } = useCurrentUser();
  const navigate = useNavigate();

  const [currentSurveyIndex, setCurrentSurveyIndex] = useState(0);

  const { surveys, loaded: surveysLoaded } = useMyEventSurveys(eventId);

  useEffect(() => {
    if (!surveysLoaded || !eventId || !organization?.id) {
      return;
    }

    if (userInitialized && !currentUser) {
      navigate(
        authUrl(eventSignAllSurveysUrl(organization.slug, { redirectTo }))
      );
    }
  }, [
    eventId,
    organization?.id,
    currentUser,
    navigate,
    redirectTo,
    userInitialized,
    organization?.slug,
    surveysLoaded,
  ]);

  const currentSurvey = surveys[currentSurveyIndex];
  if (!currentSurvey) {
    return null;
  }

  const handleComplete = async (data: SurveyFormSubmission) => {
    if (currentSurveyIndex < surveys.length - 1) {
      setCurrentSurveyIndex(currentSurveyIndex + 1);
    } else {
      if (onBeforeComplete) {
        await onBeforeComplete();
      }
      if (redirectTo) {
        navigate(redirectTo);
      }
    }
  };
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <ShowSurveyModal
      survey={currentSurvey}
      eventId={eventId}
      organizationId={organization.id}
      isOpen={true}
      onClose={handleClose}
      onComplete={handleComplete}
    />
  );
};
