import { Checkbox, Text, VStack } from "@chakra-ui/react";
import { Control, useController } from "react-hook-form";
import { Survey } from "../../../types/surveys";
import { useRecurringEvents } from "../../../hooks/useRecurringEvents";
import { useNonRecurringFutureEvents } from "../../../hooks/useNonRecurringFutureEvents";

interface CompletedByCheckboxesProps {
  control: Control<Survey>;
  organizationId: string;
}

export const CompletedByCheckboxes: React.FC<CompletedByCheckboxesProps> = ({
  control,
  organizationId,
}) => {
  const {
    field: { value: eventIds = [], onChange: setEventIds },
  } = useController({
    name: "event_ids",
    control,
  });

  const {
    field: { value: appliesToAllEvents, onChange: setAppliesToAllEvents },
  } = useController({
    name: "applies_to_all_events",
    control,
  });

  const { recurringEvents } = useRecurringEvents(organizationId);
  const { nonRecurringEvents } = useNonRecurringFutureEvents(organizationId);

  const events = [...(recurringEvents || []), ...(nonRecurringEvents || [])];

  return (
    <VStack align="stretch" spacing={3}>
      <Text fontWeight="medium" color="gray.700">
        Completed by attendees of:
      </Text>
      <VStack align="stretch" pl={2}>
        <Checkbox
          isChecked={appliesToAllEvents}
          onChange={(e) => {
            if (e.target.checked) {
              setAppliesToAllEvents(true);
              setEventIds([]);
            } else {
              setAppliesToAllEvents(false);
            }
          }}
        >
          All events
        </Checkbox>

        {!appliesToAllEvents && events && (
          <VStack align="stretch" pl={4} spacing={2}>
            {events.map((event) => (
              <Checkbox
                key={event.id}
                isChecked={eventIds.includes(event.id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setEventIds([...eventIds, event.id]);
                  } else {
                    setEventIds(eventIds.filter((id) => id !== event.id));
                  }
                }}
              >
                {event.name}
              </Checkbox>
            ))}
          </VStack>
        )}
      </VStack>
    </VStack>
  );
};
