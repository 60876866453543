import {
  FieldError,
  useFieldArray,
  useFormContext,
  useFormState,
} from "react-hook-form";
import {
  Button,
  HStack,
  IconButton,
  Text,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { BiPlus, BiTrash } from "react-icons/bi";
import { StyledRegisteredInput } from "../../../components/forms/StyledRegisteredInput";
import { get } from "lodash";
import { QuestionsFormStateLocation } from "../../../types/surveys";

interface MultipleChoiceOptionsProps {
  name: `${QuestionsFormStateLocation}.${number}`;
}

export const MultipleChoiceOptions: React.FC<MultipleChoiceOptionsProps> = ({
  name,
}) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${name}.survey_question_options`,
    rules: { required: "At least one option is required" },
  });

  const errors = useFormState({
    control,
  }).errors;

  return (
    <VStack width="100%" spacing={3} align="stretch" paddingX={8}>
      {fields.map((field, index) => (
        <HStack key={field.id}>
          <Text color="gray.600" fontWeight="medium" minWidth="24px">
            {String.fromCharCode(97 + index)}.
          </Text>
          <StyledRegisteredInput
            register={control.register}
            name={`${name}.survey_question_options.${index}.option_text`}
            options={{ required: "Option text is required" }}
            error={
              get(
                errors,
                `${name}.survey_question_options.${index}.option_text`
              ) as FieldError
            }
          />
          <IconButton
            aria-label="Remove choice"
            icon={<Icon as={BiTrash} />}
            onClick={() => remove(index)}
            variant="ghost"
            size="lg"
          />
        </HStack>
      ))}
      <Button
        onClick={() =>
          append({
            option_text: "",
            order_index: fields.length,
          })
        }
        width="fit-content"
        size="md"
        variant="ghost"
      >
        <Icon as={BiPlus} />
        Add Choice
      </Button>
    </VStack>
  );
};
