import { Control, useController } from "react-hook-form";
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { inputDefaultThemeProps } from "../../services/theme/overrides/input";
import { priceInDollars } from "../../services/payments";
import { Currency, getCurrencyByCode } from "../../services/currencies";
import { TEXT_SECONDARY_COLOR } from "../../services/theme/colors";
import { useState } from "react";

const DOLLARS_PATTERN = "^[0-9]+([.][0-9]{0,2})?$";
const DOLLARS_REGEX = new RegExp(DOLLARS_PATTERN);

const formatDollarsForDisplay = (basisPoints: number | null) => {
  if (basisPoints === null) {
    return "";
  }
  // Round to avoid floating point precision issues
  const dollarValue = Math.round(basisPoints) / 100;

  // If the value is a whole number (ends with .00), show no decimal places
  if (dollarValue % 1 === 0) {
    return dollarValue.toString();
  }

  // Otherwise, show exactly 2 decimal places
  return dollarValue.toFixed(2);
};

const formatDollarsForEdit = (basisPoints: number | null) => {
  if (basisPoints === null) {
    return "";
  }
  // Round to avoid floating point precision issues
  const dollarValue = Math.round(basisPoints) / 100;
  return dollarValue.toFixed(2);
};

interface DollarsInputProps extends FormControlProps {
  name: string;
  label: string;
  control: Control<any>;
  defaultValue?: number;
  min: number;
  currencyIsoCode?: string;
}

export const DollarsInput: React.FC<DollarsInputProps> = ({
  name,
  label,
  control,
  defaultValue,
  min,
  currencyIsoCode = "USD",
  ...formControlProps
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue || 0,
    rules: {
      min: {
        value: min,
        message: `Must be at least ${priceInDollars(currencyIsoCode, min)}`,
      },
      required: "This field is required",
    },
  });

  const [localValue, setLocalValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const currencyInfo: Currency | undefined = getCurrencyByCode(currencyIsoCode);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue === "" || DOLLARS_REGEX.test(newValue)) {
      setLocalValue(newValue);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);

    let newValue = 0;
    if (localValue !== "") {
      const parsedValue = parseFloat(localValue);
      if (!isNaN(parsedValue)) {
        newValue = Math.round(parsedValue * 100);
      }
    }

    onChange(newValue);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setLocalValue(formatDollarsForEdit(value));
  };

  const displayValue = isFocused ? localValue : formatDollarsForDisplay(value);

  return (
    <FormControl isInvalid={!!error} width="100%" {...formControlProps}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <InputGroup>
        <InputLeftAddon
          color={TEXT_SECONDARY_COLOR}
          backgroundColor="white"
          border="2px solid"
          borderRight="none"
          borderRadius="24px 0 0 24px"
          borderColor="black"
          _focusVisible={{ border: "2px dashed black" }}
        >
          {currencyInfo ? currencyInfo.symbol : currencyIsoCode}
        </InputLeftAddon>
        <Input
          type="text"
          variant="primary"
          borderRadius="0 24px 24px 0"
          placeholder="10"
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={displayValue}
          {...inputDefaultThemeProps}
        />
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};
