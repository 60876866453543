import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  VisuallyHidden,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { useController, Control, Path, FieldValues } from "react-hook-form";
import { useRef, useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

interface Option {
  value: string;
  label: string;
  icon?: React.ReactNode;
}

interface SelectMenuProps<T extends FieldValues> {
  options: Option[];
  name: Path<T>;
  control: Control<T>;
  placeholder?: string;
}

export const SelectMenu = <T extends FieldValues>({
  options,
  name,
  control,
  placeholder = "Select an option",
}: SelectMenuProps<T>) => {
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  });

  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (val: string) => {
    onChange(val);
    setIsOpen(false);
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = val;
    }
  };

  useEffect(() => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = value || "";
    }
  }, [value]);

  const selectedOption = options.find((option) => option.value === value);

  return (
    <>
      <Menu isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <MenuButton
          as={Button}
          rightIcon={<Icon as={BiChevronDown} />}
          variant="primary"
          size="lg"
          width="100%"
          onClick={() => setIsOpen(!isOpen)}
        >
          <HStack spacing={2}>
            {selectedOption?.icon}
            <Text>{selectedOption?.label || placeholder}</Text>
          </HStack>
        </MenuButton>
        <MenuList>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => handleSelect(option.value)}
            >
              <HStack spacing={2}>
                {option.icon}
                <Text>{option.label}</Text>
              </HStack>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <VisuallyHidden>
        <input
          type="hidden"
          name={name}
          ref={hiddenInputRef}
          value={value || ""}
          aria-label={`hidden select input for ${name}`}
        />
      </VisuallyHidden>
    </>
  );
};
