import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    width: "24px",
    height: "24px",
    borderRadius: "full",
    border: "2px solid black !important",
    backgroundColor: "white",
    _checked: {
      backgroundColor: "black",
      borderColor: "black",
      _before: {
        content: '""',
        display: "inline-block",
        width: "12px",
        height: "12px",
        borderRadius: "full",
        backgroundColor: "white",
      },
    },
  },
});

const defaultProps = {
  colorScheme: "black",
};

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps,
});
