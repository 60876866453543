import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useOrganization } from "../../../../providers/CurrentOrganizationProvider";
import { useEvent } from "../../../../hooks/useEvent";
import { eventShowUrl } from "../../../../services/routes/urlBuilder";
import { CompleteEventSurveysModals } from "./CompleteEventSurveysModals";

export const CompleteEventSurveys: React.FC = () => {
  const { eventId } = useParams<{ eventId: string; ticket_types: string }>();
  const { event } = useEvent(eventId);
  const { organization } = useOrganization(event?.organization_id);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const ticketTypesParam = searchParams.get("ticket_types");

  const handleClose = () => {
    if (eventId) {
      navigate(
        eventShowUrl(eventId, {
          ticket_types: ticketTypesParam
            ? JSON.parse(ticketTypesParam)
            : undefined,
        })
      );
    }
  };

  if (!eventId || !organization?.id) {
    return null;
  }

  return (
    <CompleteEventSurveysModals
      organization={organization}
      eventId={eventId}
      redirectTo={redirectTo || null}
      onClose={handleClose}
    />
  );
};
